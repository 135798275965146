/**
 * Navigation links for the app header.
 *
 * @constant
 * @type {Array<Object>}
 * @property {string} title - The title of the link.
 * @property {string} to - The path to navigate to.
 */
export const headerLinks = [
    { title: 'Notes', to: '/notes' },
    { title: 'New SC', to: '/newsc' },
    { title: 'Admin', to: '/admin' },
    { title: 'HIPAA', to: '/hipaa' },
    { title: 'Reporting', to: '/reporting' },
    { title: 'Install Fails', to: '/installfails' },
    { title: 'TSplus', to: '/licenses' },
    { title: 'Users', to: '/users' },
    { title: 'Logout', to: '/logout' }
];

/**
 * Navigation links for the 'New SC' section.
 *
 * @constant
 * @type {Array<Object>}
 * @property {string} title - The title of the link.
 * @property {string} to - The path to navigate to.
 */
export const newscLinks = [
    {
        title: 'Add New SC',
        to: '/addSC'
    },
    {
        title: 'Send Orientation Complete Email',
        to: '/completeOrientation'
    }
];

/**
 * Navigation links for the 'Admin' section.
 *
 * @constant
 * @type {Array<Object>}
 * @property {string} title - The title of the link.
 * @property {string} to - The path to navigate to.
 */
export const adminLinks = [
    {
        title: 'OAC',
        to: '/oac'
    },
    {
        title: 'OAR',
        to: '/oar'
    },
    {
        title: 'User Removal',
        to: '/userRemoval'
    },
    {
        title: 'AOAR',
        to: '/aoar'
    },
    {
        title: 'Email Jobs',
        to: '/emailJobs'
    }
];

/**
 * Navigation links for the 'HIPAA' section.
 *
 * @constant
 * @type {Array<Object>}
 * @property {string} title - The title of the link.
 * @property {string} to - The path to navigate to.
 */
export const hipaaLinks = [
    {
        title: 'Concerns',
        to: '/hipaaconcerns'
    },
    {
        title: 'Corrections',
        to: '/hipaacorrections'
    },
    {
        title: 'Send OS Fail Email',
        to: '/hipaaOsEmail'
    }
];

/**
 * Navigation links for the 'User Management' section.
 *
 * @constant
 * @type {Array<Object>}
 * @property {string} title - The title of the link.
 * @property {string} to - The path to navigate to.
 */
export const userManagementLinks = [
    {
        title: 'Create a User',
        to: '/createuser'
    },
    {
        title: 'Update a User',
        to: '/updateuser'
    },
    {
        title: 'Delete a User',
        to: '/deleteuser'
    }
];

/**
 * Navigation links for the 'Reporting' section.
 *
 * @constant
 * @type {Array<Object>}
 * @property {string} title - The title of the link.
 * @property {string} to - The path to navigate to.
 */
export const reportingLinks = [
    {
        title: 'Home',
        to: '/reporting'
    },
    {
        title: 'HD Weekly Stats',
        to: '/hdweeklystats'
    },
    {
        title: 'New SC/Admin Weekly Stats',
        to: '/newscweeklystats'
    },
    {
        title: 'NCLT Weekly Stats',
        to: '/ncltweeklystats'
    },
    {
        title: 'Query Tool',
        to: '/queryTool'
    },
    {
        title: 'Audit Tickets',
        to: '/auditTickets'
    },
    {
        title: 'TSplus Licenses',
        to: '/licenseReporting'
    }
];
