import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import CheckBox from 'rc-checkbox';
import DatePicker from 'react-datepicker';
import SubmitButton from '../common/SubmitButton.jsx';
import { runTicketAuditFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useUsers from '../../hooks/useUsers.js';
import { timePeriodOptions } from '../../config/dropdown-options.js';
import useReporting from '../../hooks/useReporting.js';
import runTicketAuditValidation from '../../validation/run-ticket-audit-validation.js';
import TicketCard from './TicketCard.jsx';
/**
 * AuditTicketsForm component for running a ticket audit.
 *
 * @component
 * @returns {JSX.Element} - The rendered AuditTicketsForm component.
 */
const AuditTicketsForm = () => {
    const [userOptions, setUserOptions] = useState([]);
    const [showCustomTime, setShowCustomTime] = useState(false);

    const { error: userError, loading: userLoading, searchUsers } = useUsers();

    const {
        error: reportingError,
        loading: reportingLoading,
        tickets,
        getRandomTickets,
        reset
    } = useReporting();

    const formik = useFormik({
        initialValues: runTicketAuditFields,
        validationSchema: runTicketAuditValidation,
        onSubmit: async (values) => {
            const modifiedValues = { ...values };

            // Get rid of null fields or empty arrays
            Object.keys(modifiedValues).forEach((key) => {
                if (
                    modifiedValues[key] === null ||
                    (Array.isArray(modifiedValues[key]) &&
                        modifiedValues[key].length === 0)
                ) {
                    delete modifiedValues[key];
                }
            });

            await getRandomTickets(modifiedValues);
        }
    });

    useEffect(() => {
        const getAllUsers = async () => {
            let returnedUsers = [];

            const helpDeskUsers = await searchUsers(
                '',
                1,
                '65c54c7f19a02a87988b158e' // Help desk
            );
            const phoneTechUsers = await searchUsers(
                '',
                1,
                '65c54c7f19a02a87988b1590' // Phone tech
            );
            const newScUsers = await searchUsers(
                '',
                1,
                '65c54c7f19a02a87988b158f' // New SC Install/Admin Tech
            );

            returnedUsers = returnedUsers.concat(
                helpDeskUsers,
                phoneTechUsers,
                newScUsers
            );

            returnedUsers.sort((a, b) =>
                a.firstName.localeCompare(b.firstName)
            );

            returnedUsers = returnedUsers.map((u) => {
                // eslint-disable-next-line no-param-reassign
                u = {
                    value: u.id,
                    label: `${u.firstName} ${u.lastName}`
                };
                return u;
            });

            formik.setFieldValue('userId', returnedUsers[0].value);

            setUserOptions(returnedUsers);
        };

        getAllUsers();
    }, []);

    useEffect(() => {
        if (showCustomTime) {
            formik.setFieldValue('timePeriod', 'custom');
        } else {
            formik.setFieldValue('timePeriod', '');
        }
    }, [showCustomTime]);

    // Scrolls to top of screen, UI is funky
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/* Form and table results */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: '50vh',
                    marginBottom: '5vh'
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%'
                    }}>
                    {/* Individual Query Form */}
                    <form
                        onSubmit={formik.handleSubmit}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            marginLeft: '30px',
                            marginTop: '2em'
                        }}>
                        <div
                            style={{
                                textAlign: 'left'
                            }}>
                            <label htmlFor="userId">User</label>
                            <br />
                            <br />
                            <div style={{ width: '60%' }}>
                                <Select
                                    id="userId"
                                    name="userId"
                                    options={userOptions}
                                    autoFocus={true}
                                    value={userOptions.find(
                                        (option) =>
                                            option.value ===
                                            formik.values.userId
                                    )}
                                    onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                            'userId',
                                            selectedOption
                                                ? selectedOption.value
                                                : null
                                        )
                                    }
                                />
                            </div>
                            {userLoading && <LoadingSpinner />}
                            {userError && (
                                <ErrorMessage message={userError.message} />
                            )}
                            {formik.touched.userId && formik.errors.userId ? (
                                <ErrorMessage message={formik.errors.userId} />
                            ) : null}
                            <br />

                            <label htmlFor="timePeriod">Time Period</label>
                            <br />
                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '80%',
                                    justifyContent: 'space-between'
                                }}>
                                {showCustomTime ? (
                                    <div className="hipaa-date-container">
                                        <div
                                            className="hipaa-date-chunk"
                                            style={{ zIndex: 0 }}>
                                            <p>Start Date</p>
                                            <DatePicker
                                                onChange={(date) =>
                                                    formik.setFieldValue(
                                                        'customStartDate',
                                                        date
                                                    )
                                                }
                                                selected={
                                                    formik.values
                                                        .customStartDate
                                                }
                                                maxDate={new Date()}
                                                minDate={new Date('2015-10-27')}
                                                isClearable
                                                showYearDropdown
                                                scrollableMonthYearDropdown
                                            />
                                        </div>
                                        <div
                                            className="hipaa-date-chunk"
                                            style={{ zIndex: 0 }}>
                                            <p>End Date</p>
                                            <DatePicker
                                                onChange={(date) =>
                                                    formik.setFieldValue(
                                                        'customEndDate',
                                                        date
                                                    )
                                                }
                                                selected={
                                                    formik.values.customEndDate
                                                }
                                                maxDate={new Date()}
                                                minDate={new Date('2015-10-27')}
                                                isClearable
                                                showYearDropdown
                                                scrollableMonthYearDropdown
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ width: '75%' }}>
                                        <Select
                                            id="timePeriod"
                                            name="timePeriod"
                                            options={timePeriodOptions}
                                            value={timePeriodOptions.find(
                                                (option) =>
                                                    option.value ===
                                                    formik.values.timePeriod
                                            )}
                                            onChange={(selectedOption) =>
                                                formik.setFieldValue(
                                                    'timePeriod',
                                                    selectedOption
                                                        ? selectedOption.value
                                                        : null
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                    <CheckBox
                                        checked={showCustomTime}
                                        onChange={() =>
                                            setShowCustomTime(!showCustomTime)
                                        }
                                    />
                                    <p>Use Custom</p>
                                </div>
                            </div>
                            {formik.touched.timePeriod &&
                            formik.errors.timePeriod ? (
                                <div
                                    style={{
                                        textAlign: 'center'
                                    }}>
                                    <ErrorMessage
                                        message={formik.errors.timePeriod}
                                    />
                                </div>
                            ) : null}
                        </div>
                        <br />
                        <br />
                        {Object.keys(formik.errors).length !== 0 &&
                        Object.keys(formik.touched).length !== 0 ? (
                            <ErrorMessage message="There are form errors above, please fix." />
                        ) : null}

                        <SubmitButton label="Get tickets" />
                    </form>
                </div>

                <br />

                {/* Ticket results */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                        alignItems: 'center'
                    }}>
                    {reportingLoading && <LoadingSpinner />}
                    {reportingError && (
                        <ErrorMessage message={reportingError.message} />
                    )}
                    {tickets && tickets.length > 0 && (
                        <>
                            <div className="reporting-ticket-audit-container">
                                {tickets.map((ticket) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <TicketCard ticket={ticket} />
                                ))}
                            </div>

                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'space-evenly'
                                }}>
                                <button
                                    className="newsc-btn"
                                    style={{ marginBottom: '5em' }}
                                    onClick={reset}>
                                    Reset
                                </button>
                            </div>
                        </>
                    )}
                    {tickets && tickets.length === 0 && (
                        <>
                            <div className="reporting-ticket-audit-container">
                                <h1>No tickets found</h1>
                            </div>

                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'space-evenly'
                                }}>
                                <button
                                    className="newsc-btn"
                                    style={{ marginBottom: '5em' }}
                                    onClick={reset}>
                                    Reset
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default AuditTicketsForm;
