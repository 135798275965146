/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOfficeObj } from '../../redux/slices/office-slice.js';
import { black, red } from '../../styles/standard-colors.js';
import ToggleVisibilityButton from '../common/ToggleVisibilityButton.jsx';
import convertPhoneNumberToNationalFormat from '../../utils/convert-phone-number-to-national.js';
import UploadQrCodeImageModal from './UploadQrCodeImageModal.jsx';
import useOffices from '../../hooks/useOffices.js';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import ErrorMessage from '../common/ErrorMessage.jsx';

/**
 * Component for displaying office information in a table format.
 * @returns {JSX.Element} JSX element representing the Office Information Table.
 */
const OfficeInfoTable = () => {
    const officeObj = useSelector(selectOfficeObj);

    const { loading, error, viewQrCodeImage, imageUrl, resetQrCodeImage } =
        useOffices();

    const [showPassword, setShowPassword] = useState(false);
    const [showUploadQrImageModal, setShowUploadQrImageModal] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const connectionColor =
        officeObj.numOfDBConnections ||
        officeObj.numOfFSConnections ||
        officeObj.numOfIVConnections ||
        officeObj.numOfPPConnections ||
        officeObj.connectionSoftware === 'cloud'
            ? black
            : red;
    const eobColor =
        officeObj.eobPath === '' || officeObj.eobPath === null ? red : black;
    const dsColor =
        officeObj.dentalSoftware === '' || officeObj.dentalSoftware === null
            ? red
            : black;
    const dsUsernameColor =
        officeObj.dsUsername === '' || officeObj.dsUsername === null
            ? red
            : black;
    const dsPasswordColor =
        officeObj.dsPassword === '' || officeObj.dsPassword === null
            ? red
            : black;
    const eClaimsColor =
        officeObj.eClaims === '' || officeObj.eClaims === null ? red : black;
    const eClaimsUsernameColor =
        officeObj.eClaimsUsername === '' || officeObj.eClaimsUsername === null
            ? red
            : black;
    const eClaimsPasswordColor =
        officeObj.eClaimsPassword === '' || officeObj.eClaimsPassword === null
            ? red
            : black;
    const eClaimsInstructionsColor =
        officeObj.eClaimsInstructions === '' ||
        officeObj.eClaimsInstructions === null
            ? red
            : black;
    const attachmentsColor =
        officeObj.attachments === '' || officeObj.attachments === null
            ? red
            : black;
    const xraysColor =
        officeObj.xrays === '' || officeObj.xrays === null ? red : black;
    const pdfPrinterColor =
        officeObj.pdfPrinter === '' || officeObj.pdfPrinter === null
            ? red
            : black;
    const backupTimesColor =
        officeObj.backupTimes === '' || officeObj.backupTimes === null
            ? red
            : black;
    const practiceBoosterUsernameColor =
        officeObj.practiceBoosterUsername === '' ||
        officeObj.practiceBoosterUsername === null
            ? red
            : black;
    const practiceBoosterPasswordColor =
        officeObj.practiceBoosterPassword === '' ||
        officeObj.practiceBoosterPassword === null
            ? red
            : black;

    /**
     * Handles the toggle of the password visibility.
     */
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <table className="office-table">
                <tbody>
                    <tr>
                        <td className="office-table-left-cell">Notes:</td>
                        <td
                            className="office-table-right-cell"
                            style={{ whiteSpace: 'pre-wrap' }}>
                            {officeObj.notes}
                        </td>
                    </tr>
                    <tr style={{ padding: '.25em' }}>
                        <td className="office-table-left-cell">
                            Linked to hub office?:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.hubId ? 'Yes' : 'No'}
                        </td>
                    </tr>
                    <tr style={{ padding: '.25em' }}>
                        <td className="office-table-left-cell">Office Name:</td>
                        <td className="office-table-right-cell">
                            {officeObj.officeName}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Phone Number:
                        </td>
                        <td className="office-table-right-cell">
                            {convertPhoneNumberToNationalFormat(
                                officeObj.phoneNumber
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">Email:</td>
                        <td className="office-table-right-cell">
                            {officeObj.officeEmail}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">POC:</td>
                        <td className="office-table-right-cell">
                            {officeObj.poc}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">IT Name:</td>
                        <td className="office-table-right-cell">
                            {officeObj.itName}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">IT Email:</td>
                        <td className="office-table-right-cell">
                            {officeObj.itEmail}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            IT Phone Number:
                        </td>
                        <td className="office-table-right-cell">
                            {convertPhoneNumberToNationalFormat(
                                officeObj.itPhoneNumber
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">Production:</td>
                        <td className="office-table-right-cell">
                            {officeObj.production}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Install Date:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.installDate
                                ? `${
                                      new Date(
                                          officeObj.installDate
                                      ).getUTCMonth() + 1
                                  }/${new Date(
                                      officeObj.installDate
                                  ).getUTCDate()}/${new Date(
                                      officeObj.installDate
                                  ).getUTCFullYear()}`
                                : ''}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Install Tech:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.installTech}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: connectionColor }}>
                            Number of DB Connections:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: connectionColor }}>
                            {officeObj.numOfDBConnections
                                ? officeObj.numOfDBConnections
                                : 0}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: connectionColor }}>
                            Number of IV Connections:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: connectionColor }}>
                            {officeObj.numOfIVConnections
                                ? officeObj.numOfIVConnections
                                : 0}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: connectionColor }}>
                            Number of PB Connections:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: connectionColor }}>
                            {officeObj.numOfPPConnections
                                ? officeObj.numOfPPConnections
                                : 0}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: connectionColor }}>
                            Number of FS Connections:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: connectionColor }}>
                            {officeObj.numOfFSConnections
                                ? officeObj.numOfFSConnections
                                : 0}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: eobColor }}>
                            EOB Path:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: eobColor }}>
                            {officeObj.eobPath}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Connection Software:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.connectionSoftware}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Unique VPN Addendum on File:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.uniqueVPN &&
                            officeObj.uniqueVPN.addendumOnFile
                                ? 'Yes'
                                : 'No'}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: dsColor }}>
                            Dental Sofware:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: dsColor }}>
                            {officeObj.dentalSoftware}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: dsUsernameColor }}>
                            Dental Software Username
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: dsUsernameColor }}>
                            {officeObj.dsUsername}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: dsPasswordColor }}>
                            Dental Software Password:
                        </td>
                        <td
                            className="office-table-right-cell-pwd"
                            style={{ color: dsPasswordColor }}>
                            {showPassword && officeObj.dsPassword
                                ? officeObj.dsPassword
                                : !showPassword && officeObj.dsPassword
                                  ? '*'.repeat(officeObj.dsPassword.length)
                                  : ''}
                            {officeObj.dsPassword && (
                                <ToggleVisibilityButton
                                    condition={showPassword}
                                    onClick={handleTogglePassword}
                                />
                            )}
                            {!officeObj.dsPassword && <p></p>}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Second Dental Software Username:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.dsUsername2}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Second Dental Software Password:
                        </td>
                        <td className="office-table-right-cell-pwd">
                            {showPassword && officeObj.dsPassword2
                                ? officeObj.dsPassword2
                                : !showPassword && officeObj.dsPassword2
                                  ? '*'.repeat(officeObj.dsPassword2.length)
                                  : ''}
                            {officeObj.dsPassword2 && (
                                <ToggleVisibilityButton
                                    condition={showPassword}
                                    onClick={handleTogglePassword}
                                />
                            )}
                            {!officeObj.dsPassword2 && <p></p>}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Dental Software URL:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.dsURL}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: eClaimsColor }}>
                            eClaims:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: eClaimsColor }}>
                            {officeObj.eClaims}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: eClaimsUsernameColor }}>
                            eClaims Username:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: eClaimsUsernameColor }}>
                            {officeObj.eClaimsUsername}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: eClaimsPasswordColor }}>
                            eClaims Password:
                        </td>
                        <td
                            className="office-table-right-cell-pwd"
                            style={{ color: eClaimsPasswordColor }}>
                            {showPassword && officeObj.eClaimsPassword
                                ? officeObj.eClaimsPassword
                                : !showPassword && officeObj.eClaimsPassword
                                  ? '*'.repeat(officeObj.eClaimsPassword.length)
                                  : ''}
                            {officeObj.eClaimsPassword && (
                                <ToggleVisibilityButton
                                    condition={showPassword}
                                    onClick={handleTogglePassword}
                                />
                            )}
                            {!officeObj.eClaimsPassword && <p></p>}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: eClaimsInstructionsColor }}>
                            eClaims Instructions:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: eClaimsInstructionsColor }}>
                            {officeObj.eClaimsInstructions}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: attachmentsColor }}>
                            Attachments:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: attachmentsColor }}>
                            {officeObj.attachments}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: xraysColor }}>
                            X-Rays:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: xraysColor }}>
                            {officeObj.xrays}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: pdfPrinterColor }}>
                            PDF Printer:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: pdfPrinterColor }}>
                            {officeObj.pdfPrinter}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: backupTimesColor }}>
                            Backup Times:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: backupTimesColor }}>
                            {officeObj.backupTimes}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Firewall Info:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.firewallInfo}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: practiceBoosterUsernameColor }}>
                            Practice Booster Username:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ color: practiceBoosterUsernameColor }}>
                            {officeObj.practiceBoosterUsername}
                        </td>
                    </tr>
                    <tr>
                        <td
                            className="office-table-left-cell"
                            style={{ color: practiceBoosterPasswordColor }}>
                            Practice Booster Password:
                        </td>
                        <td
                            className="office-table-right-cell-pwd"
                            style={{ color: practiceBoosterPasswordColor }}>
                            {showPassword && officeObj.practiceBoosterPassword
                                ? officeObj.practiceBoosterPassword
                                : !showPassword &&
                                    officeObj.practiceBoosterPassword
                                  ? '*'.repeat(
                                        officeObj.practiceBoosterPassword.length
                                    )
                                  : ''}
                            {officeObj.practiceBoosterPassword && (
                                <ToggleVisibilityButton
                                    condition={showPassword}
                                    onClick={handleTogglePassword}
                                />
                            )}
                            {!officeObj.practiceBoosterPassword && <p></p>}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Zilla Number:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.twilioActivated
                                ? convertPhoneNumberToNationalFormat(
                                      officeObj.twilioNumber
                                  )
                                : 'N/A'}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Caller ID Verified:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.callerIdVerified ? 'Yes' : 'No'}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Is Auth for KOC:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.isKoc ? 'Yes' : 'No'}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Is Copilot Office?:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.isCopilotOffice ? 'Yes' : 'No'}
                        </td>
                    </tr>
                    <tr style={{ padding: '.25em' }}>
                        <td className="office-table-left-cell">
                            Copilot Server Name:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.copilotServerName
                                ? officeObj.copilotServerName
                                : 'N/A'}
                        </td>
                    </tr>
                    <tr style={{ padding: '.25em' }}>
                        <td className="office-table-left-cell">
                            Copilot Server Username:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.copilotServerUsername
                                ? officeObj.copilotServerUsername
                                : 'N/A'}
                        </td>
                    </tr>
                    <tr style={{ padding: '.25em' }}>
                        <td className="office-table-left-cell">
                            Copilot Server Password:
                        </td>
                        <td className="office-table-right-cell-pwd">
                            {showPassword && officeObj.copilotServerPassword
                                ? officeObj.copilotServerPassword
                                : !showPassword &&
                                    officeObj.copilotServerPassword
                                  ? '*'.repeat(
                                        officeObj.copilotServerPassword.length
                                    )
                                  : 'N/A'}
                            {officeObj.copilotServerPassword && (
                                <ToggleVisibilityButton
                                    condition={showPassword}
                                    onClick={handleTogglePassword}
                                />
                            )}
                            {!officeObj.copilotServerPassword && <p></p>}
                        </td>
                    </tr>
                    <tr>
                        <td className="office-table-left-cell">
                            Copilot Notes:
                        </td>
                        <td
                            className="office-table-right-cell"
                            style={{ whiteSpace: 'pre-wrap' }}>
                            {officeObj.copilotNotes
                                ? officeObj.copilotNotes
                                : 'N/A'}
                        </td>
                    </tr>
                    <tr style={{ padding: '.25em' }}>
                        <td className="office-table-left-cell">
                            Nextcloud Client Password:
                        </td>
                        <td className="office-table-right-cell-pwd">
                            {showPassword && officeObj.nextCloudPassword
                                ? officeObj.nextCloudPassword
                                : !showPassword && officeObj.nextCloudPassword
                                  ? '*'.repeat(
                                        officeObj.nextCloudPassword.length
                                    )
                                  : 'N/A'}
                            {officeObj.nextCloudPassword && (
                                <ToggleVisibilityButton
                                    condition={showPassword}
                                    onClick={handleTogglePassword}
                                />
                            )}
                            {!officeObj.nextCloudPassword && <p></p>}
                        </td>
                    </tr>
                    <tr style={{ padding: '.25em' }}>
                        <td className="office-table-left-cell">
                            Nextcloud Client QR Code:
                        </td>
                        <td className="office-table-right-cell">
                            {officeObj.nextCloudQrCodeImageObjectKey &&
                                !imageUrl && (
                                    <>
                                        <button
                                            className="office-credential-btn"
                                            onClick={() =>
                                                viewQrCodeImage(
                                                    officeObj.nextCloudQrCodeImageObjectKey
                                                )
                                            }>
                                            View QR Code
                                        </button>
                                        <br />
                                        <br />
                                    </>
                                )}
                            {officeObj.nextCloudQrCodeImageObjectKey &&
                                imageUrl && (
                                    <>
                                        <button
                                            className="office-credential-btn"
                                            onClick={() => resetQrCodeImage()}>
                                            Close QR Code
                                        </button>
                                        <br />
                                        <br />
                                    </>
                                )}
                            {imageUrl && (
                                <>
                                    <img
                                        src={imageUrl}
                                        alt="thumbnail"
                                        width="350px"
                                        height="350px"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setIsFullscreen(true)}
                                    />
                                    <br />
                                    <br />
                                </>
                            )}
                            {/* Fullscreen modal */}
                            {imageUrl && isFullscreen && (
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        zIndex: 1000
                                    }}
                                    onClick={() => setIsFullscreen(false)}>
                                    <img
                                        src={imageUrl}
                                        alt="Fullscreen"
                                        style={{
                                            maxWidth: '90%',
                                            maxHeight: '90%',
                                            objectFit: 'contain'
                                        }}
                                    />
                                </div>
                            )}
                            {!officeObj.nextCloudQrCodeImageObjectKey && (
                                <button
                                    className="office-credential-btn"
                                    onClick={() =>
                                        setShowUploadQrImageModal(true)
                                    }>
                                    Upload QR Code Image
                                </button>
                            )}
                            {officeObj.nextCloudQrCodeImageObjectKey && (
                                <button
                                    className="office-credential-btn"
                                    onClick={() =>
                                        setShowUploadQrImageModal(true)
                                    }>
                                    Upload new QR Code Image
                                </button>
                            )}
                            {loading && <LoadingSpinner />}
                            {error && <ErrorMessage message={error.message} />}
                        </td>
                    </tr>
                </tbody>
            </table>
            {showUploadQrImageModal && (
                <UploadQrCodeImageModal
                    officeId={officeObj.id}
                    setShowModal={setShowUploadQrImageModal}
                />
            )}
        </>
    );
};

export default OfficeInfoTable;
