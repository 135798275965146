import { useState } from 'react';
import convertError from '../utils/error-converter.js';
import UserFriendlyError from '../utils/UserFriendlyError.js';
import callApi from '../utils/call-api.js';
import analyzeApiResponse from '../utils/analyze-api-response.js';

/**
 * Custom React hook for managing Reporting-related data.
 *
 * @returns {Object} An object containing state variables and functions related to user data.
 * @property {Array<Object>} tables - The array of returned table objects (empty if none)
 * @property {boolean} loading - A boolean indicating whether data is currently being loaded.
 * @property {Object|null} error - An error object containing details about any encountered error.
 * @property {Function} getWeeklyStats - A function to fetch weekly stats.
 */
const useReporting = () => {
    const [tables, setTables] = useState([]);
    const [table, setTable] = useState(null);
    const [charts, setCharts] = useState([]);
    const [queryType, setQueryType] = useState('');
    const [tickets, setTickets] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Gets weekly stats for a specified IT segment.
     *
     * @async
     * @function
     * @param {string} [itSegment=''] - The It segment to filter by.
     * @returns {Promise<Array>} A promise that resolves with a list of returned tables.
     */
    const getWeeklyStats = async (itSegment) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/reporting/dashboard/weekly-stats?itSegment=${itSegment}`;

            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const data = await response.json();
            setTables(data);
            return data;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets queried stats with specified query parameters.
     *
     * @async
     * @function
     * @param {string} queryOptions
     * @returns {Promise<Object>} A promise that resolves with an object of the returned tables and charts.
     */
    const runQuery = async (queryOptions) => {
        try {
            setLoading(true);
            setError(null);
            setTable(null);
            setTables([]);
            setCharts([]);
            setQueryType('');

            // Convert query options to query string
            const queryString = Object.keys(queryOptions)
                .map((key) => {
                    const value = queryOptions[key];

                    // Check if value is an array
                    if (Array.isArray(value)) {
                        return value
                            .map(
                                (arrayItem) =>
                                    `${encodeURIComponent(
                                        key
                                    )}=${encodeURIComponent(arrayItem)}`
                            )
                            .join('&');
                        // eslint-disable-next-line no-else-return
                    } else {
                        return `${encodeURIComponent(key)}=${encodeURIComponent(
                            value
                        )}`;
                    }
                })
                .join('&');

            const path = `/reporting/query?${queryString}`;

            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const {
                table: returnedTable,
                tables: returnedTables,
                charts: returnedCharts,
                queryType: returnedQueryType
            } = await response.json();
            setTable(returnedTable);
            setTables(returnedTables);
            setCharts(returnedCharts);
            setQueryType(returnedQueryType);
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Resets all stats.
     *
     * @async
     * @function
     * @returns {Promise<void>} A promise that resolves when finished.
     */
    const reset = async () => {
        try {
            setError(null);
            setTable(null);
            setTables([]);
            setCharts([]);
            setTickets(null);
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Fetches ticket tag options from the API.
     *
     * This asynchronous function fetches a list of ticket tags from the `/reporting/tickets/ticket-tags` endpoint.
     * It handles loading state, errors, and processes the response.
     *
     * @async
     * @function getTicketTagOptions
     * @returns {Promise<Array>} Resolves with the ticket tag options.
     */
    const getTicketTagOptions = async () => {
        try {
            setLoading(true);
            setError(null);

            const path = `/reporting/tickets/ticket-tags`;

            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const data = await response.json();
            return data;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Fetches ticket type options from the API.
     *
     * This asynchronous function fetches a list of ticket types from the `/reporting/tickets/ticket-types` endpoint.
     * It handles loading state, errors, and processes the response.
     *
     * @async
     * @function getTicketTypeOptions
     * @returns {Promise<Array>} Resolves with the ticket type options.
     */
    const getTicketTypeOptions = async () => {
        try {
            setLoading(true);
            setError(null);

            const path = `/reporting/tickets/ticket-types`;

            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const data = await response.json();
            return data;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets random tickets with specified query parameters.
     *
     * @async
     * @function
     * @param {string} queryOptions
     * @returns {Promise<Array<Object>>} A promise that resolves with an array of returned ticket objects.
     */
    const getRandomTickets = async (queryOptions) => {
        try {
            setLoading(true);
            setError(null);
            setTickets([]);

            // Convert query options to query string
            const queryString = Object.keys(queryOptions)
                .map((key) => {
                    const value = queryOptions[key];

                    // Check if value is an array
                    if (Array.isArray(value)) {
                        return value
                            .map(
                                (arrayItem) =>
                                    `${encodeURIComponent(
                                        key
                                    )}=${encodeURIComponent(arrayItem)}`
                            )
                            .join('&');
                        // eslint-disable-next-line no-else-return
                    } else {
                        return `${encodeURIComponent(key)}=${encodeURIComponent(
                            value
                        )}`;
                    }
                })
                .join('&');

            const path = `/reporting/tickets/random?${queryString}`;

            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedTickets = await response.json();
            setTickets(returnedTickets);
            return returnedTickets;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets queried stats with specified query parameters.
     *
     * @async
     * @function
     * @param {string} queryOptions
     * @returns {Promise<Object>} A promise that resolves with an object of the returned tables and charts.
     */
    const getLicenseReportingData = async (queryOptions) => {
        try {
            setLoading(true);
            setError(null);
            setTable(null);
            setCharts([]);

            // Convert query options to query string
            const queryString = Object.keys(queryOptions)
                .map((key) => {
                    const value = queryOptions[key];

                    // Check if value is an array
                    if (Array.isArray(value)) {
                        return value
                            .map(
                                (arrayItem) =>
                                    `${encodeURIComponent(
                                        key
                                    )}=${encodeURIComponent(arrayItem)}`
                            )
                            .join('&');
                        // eslint-disable-next-line no-else-return
                    } else {
                        return `${encodeURIComponent(key)}=${encodeURIComponent(
                            value
                        )}`;
                    }
                })
                .join('&');

            const path = `/reporting/licenses?${queryString}`;

            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const { table: returnedTable, charts: returnedCharts } =
                await response.json();
            setTable(returnedTable);
            setCharts(returnedCharts);
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        tables,
        getWeeklyStats,
        table,
        charts,
        runQuery,
        queryType,
        reset,
        getTicketTagOptions,
        getTicketTypeOptions,
        tickets,
        getRandomTickets,
        getLicenseReportingData
    };
};

export default useReporting;
