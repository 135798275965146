import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './styles/App.css';
import LoginPage from './pages/LoginPage.jsx';
import Header from './components/header/Header.jsx';
import HomePage from './pages/HomePage.jsx';
import OfficeNotesListPage from './pages/office-notes/OfficeNotesListPage.jsx';
import CreateOfficePage from './pages/office-notes/CreateOfficePage.jsx';
import ViewOfficeNotePage from './pages/office-notes/ViewOfficeNotePage.jsx';
import AdminAutomationsHomePage from './pages/admin-automations/AdminAutomationsHomePage.jsx';
import OacPage from './pages/admin-automations/OacPage.jsx';
import OarPage from './pages/admin-automations/OarPage.jsx';
import AoarPage from './pages/admin-automations/AoarPage.jsx';
import UserRemovalPage from './pages/admin-automations/UserRemovalPage.jsx';
import AoarEmailJobsPage from './pages/admin-automations/AoarEmailJobsPage.jsx';
import UsersHomePage from './pages/users/UsersHomePage.jsx';
import CreateUserPage from './pages/users/CreateUserPage.jsx';
import EditUserPage from './pages/users/EditUserPage.jsx';
import DeleteUserPage from './pages/users/DeleteUserPage.jsx';
import InstallFailsListPage from './pages/install-fails/InstallFailsListPage.jsx';
import HipaaHomePage from './pages/hipaa/HipaaHomePage.jsx';
import HipaaConcernsPage from './pages/hipaa/HipaaConcernsPage.jsx';
import HipaaCorrectionsPage from './pages/hipaa/HipaaCorrectionsPage.jsx';
import SendOsFailEmailToOfficePage from './pages/hipaa/SendOsFailEmailToOfficePage.jsx';
import NewScCardsListPage from './pages/new-sc-vetting-cards/NewScCardsListPage.jsx';
import CreateNewScPage from './pages/new-sc-vetting-cards/CreateNewScPage.jsx';
import AddUserToPlatformsPage from './pages/new-sc-vetting-cards/AddUserToPlatformsPage.jsx';
import ViewNewScCardPage from './pages/new-sc-vetting-cards/ViewNewScCardPage.jsx';
import ReportingDashboardPage from './pages/reporting/ReportingDashboardPage.jsx';
import TemporaryPage from './pages/TemporaryPage.jsx';
import WeeklyStatsPage from './pages/reporting/WeeklyStatsPage.jsx';
import QueryToolPage from './pages/reporting/QueryToolPage.jsx';
import AuditTicketPage from './pages/reporting/AuditTicketPage.jsx';
import AddTsPlusLicenseToComputerPage from './pages/office-notes/AddTsPlusLicenseToComputerPage.jsx';
import TsPlusLicensesListPage from './pages/ts-plus-licenses/TsPlusLicensesListPage.jsx';
import CreateUpdateTsPlusLicensePage from './pages/ts-plus-licenses/CreateUpdateTsPlusLicensePage.jsx';
import LicenseReportingDataPage from './pages/reporting/LicenseReportingDataPage.jsx';
import CreateUpdateManualTsPlusLicensePage from './pages/ts-plus-licenses/CreateUpdateManualTsPlusLicensePage.jsx';

function App() {
    const accesstoken = sessionStorage.getItem('accessToken');
    const refreshToken = sessionStorage.getItem('refreshToken');

    // If not logged in, load login screen
    if (!accesstoken || !refreshToken) {
        return (
            <Router>
                <Routes>
                    <Route path="/*" element={<LoginPage />} />
                </Routes>
            </Router>
        );
    }

    return (
        <div className="app">
            <Router>
                <Header />
                <Routes>
                    {/* Home page */}
                    <Route path="/" element={<HomePage />} />

                    {/* Office note pages */}
                    <Route path="/notes" element={<OfficeNotesListPage />} />
                    <Route
                        path="/createOffice"
                        element={<CreateOfficePage />}
                    />
                    <Route
                        path="/office/:officeId"
                        element={<ViewOfficeNotePage />}
                    />
                    <Route
                        path="/addTsPlusLicense"
                        element={<AddTsPlusLicenseToComputerPage />}
                    />

                    {/* Admin automatin pages */}
                    <Route
                        path="/admin"
                        element={<AdminAutomationsHomePage />}
                    />
                    <Route path="/oac" element={<OacPage />} />
                    <Route path="/oar" element={<OarPage />} />
                    <Route path="/aoar" element={<AoarPage />} />
                    <Route path="/userRemoval" element={<UserRemovalPage />} />
                    <Route path="/emailJobs" element={<AoarEmailJobsPage />} />

                    {/* User management pages */}
                    <Route path="/users" element={<UsersHomePage />} />
                    <Route path="/createuser" element={<CreateUserPage />} />
                    <Route path="/updateuser" element={<EditUserPage />} />
                    <Route path="/deleteuser" element={<DeleteUserPage />} />

                    {/* Install fails pages */}
                    <Route
                        path="/installfails"
                        element={<InstallFailsListPage />}
                    />

                    {/* HIPAA pages */}
                    <Route path="/hipaa" element={<HipaaHomePage />} />
                    <Route
                        path="/hipaaconcerns"
                        element={<HipaaConcernsPage />}
                    />
                    <Route
                        path="/hipaacorrections"
                        element={<HipaaCorrectionsPage />}
                    />
                    <Route
                        path="/hipaaOsEmail"
                        element={<SendOsFailEmailToOfficePage />}
                    />

                    {/* New SC Vetting Card pages */}
                    <Route path="/newsc" element={<NewScCardsListPage />} />
                    <Route
                        path="/addVettingCard"
                        element={<CreateNewScPage />}
                    />
                    <Route path="/addSC" element={<AddUserToPlatformsPage />} />
                    <Route
                        path="/newsc/:cardId"
                        element={<ViewNewScCardPage />}
                    />

                    {/* Reporting pages */}
                    <Route
                        path="/reporting"
                        element={<ReportingDashboardPage />}
                    />
                    <Route
                        path="/ncltweeklystats"
                        element={
                            <WeeklyStatsPage title="NCLT" itSegment="nclt" />
                        }
                    />
                    <Route
                        path="/hdWeeklyStats"
                        element={<WeeklyStatsPage title="HD" itSegment="hd" />}
                    />
                    <Route
                        path="/newscweeklystats"
                        element={
                            <WeeklyStatsPage
                                title="New SC Install/Admin"
                                itSegment="newsc/admin"
                            />
                        }
                    />
                    <Route path="/queryTool" element={<QueryToolPage />} />
                    <Route path="/auditTickets" element={<AuditTicketPage />} />
                    <Route
                        path="/licenseReporting"
                        element={<LicenseReportingDataPage />}
                    />

                    {/* TSplus licenses pages */}
                    <Route
                        path="/licenses"
                        element={<TsPlusLicensesListPage />}
                    />
                    <Route
                        path="/editLicense"
                        element={<CreateUpdateTsPlusLicensePage />}
                    />
                    <Route
                        path="/addManualLicense"
                        element={<CreateUpdateManualTsPlusLicensePage />}
                    />

                    {/* Temporary page */}
                    <Route path="/temporary" element={<TemporaryPage />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
