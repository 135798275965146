import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import httpStatus from 'http-status';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import SubmitButton from '../common/SubmitButton.jsx';
import { runOacFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useAutomations from '../../hooks/useAutomations.js';
import { runOacValidation } from '../../validation/admin-automation-validations.js';
import camelCaseToSentence from '../../utils/camel-case-to-sentence.js';
import useOffices from '../../hooks/useOffices.js';

/**
 * OacForm component for rendering the form to trigger the OAC automation process.
 *
 * @component
 * @returns {JSX.Element} - The rendered OacForm component.
 */
const OacForm = () => {
    const [processResultAllOk, setProcessResultAllOk] = useState(true);
    const [specificErrorsMessage, setSpecificErrorsMessage] = useState('');
    const [selectedOfficeId, setSelectedOfficeId] = useState(null);
    const [officeDropdownOptions, setOfficeDropdownOptions] = useState([]);
    const [bulkOacActive, setBulkOacActive] = useState(true);

    const {
        error: automationError,
        loading: automationLoading,
        processResult,
        runOac,
        getOfficeAssignedUsers,
        hubOfficeUsers,
        reset: automationsReset
    } = useAutomations();

    const {
        error: officeError,
        loading: officeLoading,
        getAllOffices,
        getOffice,
        office,
        reset: officeReset
    } = useOffices();

    const formik = useFormik({
        initialValues: runOacFormFields,
        validationSchema: runOacValidation,
        onSubmit: async (values) => {
            await runOac(values);
        }
    });

    useEffect(() => {
        const getOffices = async () => {
            let returnedOffices = [];
            returnedOffices = await getAllOffices();
            returnedOffices = returnedOffices.map((o) => {
                // eslint-disable-next-line no-param-reassign
                o = {
                    value: o.id,
                    label: o.officeSysName
                };
                return o;
            });

            setOfficeDropdownOptions(returnedOffices);
        };

        getOffices();
    }, []);

    useEffect(() => {
        const getOfficeDetails = async () => {
            automationsReset();
            officeReset();
            formik.resetForm();
            if (selectedOfficeId) {
                const returnedOffice = await getOffice(selectedOfficeId);
                if (returnedOffice && returnedOffice.hubId) {
                    await getOfficeAssignedUsers(returnedOffice.hubId);
                }
            }
        };
        getOfficeDetails();
    }, [selectedOfficeId]);

    const handleClickLeft = () => {
        if (!bulkOacActive) {
            setBulkOacActive(true);
            setSelectedOfficeId(null);
            automationsReset();
            officeReset();
            formik.resetForm();
        }
    };

    const handleClickRight = () => {
        if (bulkOacActive) {
            setBulkOacActive(false);
            setSelectedOfficeId(null);
            automationsReset();
            officeReset();
            formik.resetForm();
        }
    };

    useEffect(() => {
        /**
         * Checks the status codes of the result object and handles proper messaging
         */
        const handleStatusCodes = () => {
            let errorMessage = 'The following failed:\n\n';

            if (processResult) {
                // Grab status codes
                const statusCodes = Object.values(processResult);
                if (statusCodes.every((code) => code === 200)) {
                    setProcessResultAllOk(true);
                } else {
                    setProcessResultAllOk(false);

                    const keys = Object.keys(processResult);
                    keys.forEach((key) => {
                        if (processResult[key] !== 200) {
                            // Converts result codes to user friendly text and sets to message
                            errorMessage += `${camelCaseToSentence(key)}: ${
                                httpStatus[processResult[key].toString()]
                            }\n`;
                        }
                    });
                }
            }

            setSpecificErrorsMessage(errorMessage);
        };

        handleStatusCodes();
    }, [processResult]);

    return (
        <>
            {/* Toggle button for bulk vs manual process */}
            <div
                style={{
                    display: 'flex',
                    width: '50%',
                    height: '40px',
                    marginBottom: '2em',
                    marginLeft: '30px',
                    marginTop: '2em'
                }}>
                <div
                    className={
                        bulkOacActive
                            ? 'reporting-query-selector-active'
                            : 'reporting-query-selector-inactive'
                    }
                    onClick={handleClickLeft}
                    id="reporting-query-selector-left">
                    <p
                        style={{
                            color: bulkOacActive ? 'white' : '#2361DC'
                        }}>
                        Bulk
                    </p>
                </div>
                <div
                    className={
                        !bulkOacActive
                            ? 'reporting-query-selector-active'
                            : 'reporting-query-selector-inactive'
                    }
                    id="reporting-query-selector-right"
                    onClick={handleClickRight}>
                    <p
                        style={{
                            color: !bulkOacActive ? 'white' : '#2361DC'
                        }}>
                        Manual
                    </p>
                </div>
            </div>

            {/* Bulk OAC Form */}
            {bulkOacActive && (
                <>
                    <div className="newsc-form-container">
                        <p className="form-label">Select an office</p>
                        <Select
                            id="selectUser"
                            name="selectUser"
                            options={officeDropdownOptions}
                            onChange={(e) => setSelectedOfficeId(e.value)}
                        />
                    </div>
                    <br />
                    <br />
                    {officeLoading && <LoadingSpinner />}
                    {officeError && (
                        <ErrorMessage message={officeError.message} />
                    )}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            height: 'fit-content'
                        }}>
                        {office && !office.hubId && (
                            <p style={{ margin: '1em' }}>
                                Office is not linked to a hub office. Please
                                return when linked.
                            </p>
                        )}
                        {office &&
                            office.hubId &&
                            !automationLoading &&
                            !automationError &&
                            hubOfficeUsers.length < 1 && (
                                <p style={{ margin: '1em' }}>
                                    Office has no users assigned.
                                </p>
                            )}
                        {office &&
                            office.hubId &&
                            hubOfficeUsers.length > 0 && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '50%',
                                        height: 'fit-content',
                                        justifyContent: 'flex-start',
                                        alignContent: 'left'
                                    }}>
                                    <h2>Hub Assigned Users</h2>
                                    {hubOfficeUsers.map((user) => (
                                        <form
                                            onSubmit={async (e) => {
                                                e.preventDefault();
                                                await Promise.all([
                                                    formik.setFieldValue(
                                                        'scFullName',
                                                        user.scFullName
                                                    ),
                                                    formik.setFieldValue(
                                                        'scEassistEmail',
                                                        user.scEassistEmail.toLowerCase()
                                                    ),
                                                    formik.setFieldValue(
                                                        'officeSystematicName',
                                                        office.officeSysName.toLowerCase()
                                                    )
                                                ]);
                                                formik.handleSubmit();
                                            }}
                                            key={uuidv4()}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    margin: '1em',
                                                    alignItems: 'center',
                                                    justifyContent: 'left'
                                                }}>
                                                <p style={{ margin: '1em' }}>
                                                    {user.scEassistEmail.toLowerCase()}
                                                </p>
                                                <SubmitButton
                                                    label="Run"
                                                    style={{
                                                        width: '5em'
                                                    }}
                                                />
                                            </div>
                                        </form>
                                    ))}
                                </div>
                            )}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '50%',
                                height: 'fit-content',
                                justifyContent: 'flex-start',
                                alignContent: 'left'
                            }}>
                            {automationLoading && <LoadingSpinner />}
                            {automationError && (
                                <ErrorMessage
                                    message={automationError.message}
                                />
                            )}
                            {processResult && processResultAllOk ? (
                                <SuccessMessage message="OAC process ran successfully" />
                            ) : null}
                            {processResult && !processResultAllOk ? (
                                <ErrorMessage message={specificErrorsMessage} />
                            ) : null}
                        </div>
                    </div>
                </>
            )}

            {/* Manual OAC Form */}
            {!bulkOacActive && (
                <>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="newsc-form-container">
                            <label htmlFor="scFullName" className="form-label">
                                SC Full Name
                            </label>
                            <input
                                id="scFullName"
                                type="text"
                                className="newsc-input"
                                placeholder="e.g. John Doe"
                                {...formik.getFieldProps('scFullName')}
                            />
                            {formik.touched.scFullName &&
                            formik.errors.scFullName ? (
                                <ErrorMessage
                                    message={formik.errors.scFullName}
                                />
                            ) : null}

                            <label
                                htmlFor="scEassistEmail"
                                className="form-label">
                                SC eAssist Email
                            </label>
                            <input
                                id="scEassistEmail"
                                type="text"
                                className="newsc-input"
                                placeholder="e.g. john.doe@eassist.me"
                                {...formik.getFieldProps('scEassistEmail')}
                            />
                            {formik.touched.scEassistEmail &&
                            formik.errors.scEassistEmail ? (
                                <ErrorMessage
                                    autofocus
                                    message={formik.errors.scEassistEmail}
                                />
                            ) : null}
                            <label
                                htmlFor="officeSystematicName"
                                className="form-label">
                                Office Systematic Name
                            </label>
                            <input
                                id="officeSystematicName"
                                type="text"
                                className="newsc-input"
                                placeholder="e.g. ut.roy.anderson"
                                {...formik.getFieldProps(
                                    'officeSystematicName'
                                )}
                            />
                            {formik.touched.officeSystematicName &&
                            formik.errors.officeSystematicName ? (
                                <ErrorMessage
                                    message={formik.errors.officeSystematicName}
                                />
                            ) : null}
                            <SubmitButton label="Run" />
                        </div>
                    </form>
                    <br />
                    <br />
                    {automationLoading && <LoadingSpinner />}
                    {Object.keys(formik.errors).length !== 0 && (
                        <ErrorMessage message="There are form errors above, please fix." />
                    )}
                    {automationError && (
                        <ErrorMessage message={automationError.message} />
                    )}
                    {processResult && processResultAllOk ? (
                        <SuccessMessage message="OAC process ran successfully" />
                    ) : null}
                    {processResult && !processResultAllOk ? (
                        <ErrorMessage message={specificErrorsMessage} />
                    ) : null}
                </>
            )}
        </>
    );
};

export default OacForm;
