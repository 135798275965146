import { useLocation } from 'react-router-dom';
import BackArrow from '../../components/common/BackArrow.jsx';
import AddTsPlusLicenseToComputerForm from '../../components/office-notes/AddTsPlusLicenseToComputerForm.jsx';

/**
 * Component for rendering the AddTsPlusLicenseToComputer page.
 * @returns {JSX.Element} AddTsPlusLicenseToComputer page component.
 */
const AddTsPlusLicenseToComputerPage = () => {
    const location = useLocation();
    const { computerId, computer, officeSysName } = location.state;

    return (
        <div>
            <div className="newsc-container">
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>Add TSplus License</h2>
                </div>
                <br />
                <AddTsPlusLicenseToComputerForm
                    computerId={computerId}
                    computer={computer}
                    officeSysName={officeSysName}
                />
            </div>
        </div>
    );
};

export default AddTsPlusLicenseToComputerPage;
