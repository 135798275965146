import * as Yup from 'yup';

/**
 * Yup validation schema for running a ticket audit.
 *
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} timePeriod - Validation for the time period.
 * @property {Yup.DateSchema} customStartDate - Validation for the custom start date.
 * @property {Yup.DateSchema} customEndDate - Validation for the custom end date.
 * @property {Yup.StringSchema} userId - Validation for the user ID.
 */
const runTicketAuditValidation = Yup.object({
    timePeriod: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    customStartDate: Yup.date().nullable(),
    customEndDate: Yup.date().nullable(),
    userId: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required')
});

export default runTicketAuditValidation;
