import { useState } from 'react';
import convertError from '../utils/error-converter.js';
import UserFriendlyError from '../utils/UserFriendlyError.js';
import callApi from '../utils/call-api.js';
import analyzeApiResponse from '../utils/analyze-api-response.js';

/**
 * Custom React hook for managing license-related data.
 *
 * @returns {Object} An object containing state variables and functions related to license data.
 * @property {Object|null} license - The returned license (null if none returned).
 * @property {boolean} loading - A boolean indicating whether data is currently being loaded.
 * @property {Object|null} error - An error object containing details about any encountered error.
 * @property {Function} createLicense - A function to create a new license.
 */
const useTsPlusLicenses = () => {
    const [license, setLicense] = useState(null);
    const [licenses, setLicenses] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Gets all licenses.
     *
     * @async
     * @function
     *
     * @param {boolean} includeAll - whether or not to include computer and office information
     * @returns {Promise<Array>} A promise that resolves with a list of returned licenses.
     */
    const getAllLicenses = async (includeAll = false) => {
        try {
            setLoading(true);
            setError(null);

            const path = includeAll
                ? `/licenses?includeAll=${true}`
                : '/licenses';
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const results = await response.json();
            setLicenses(results);
            return results;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets oldest available licenses.
     *
     * @async
     * @function
     * @returns {Promise<Array>} A promise that resolves with a list of returned licenses.
     */
    const getOldestAvailableLicenses = async () => {
        try {
            setLoading(true);
            setError(null);

            const path = `/licenses/search/oldest/available`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const {
                results: returnedLicenses,
                totalPages: returnedTotalPages
            } = await response.json();
            setLicenses(returnedLicenses);
            setTotalPages(returnedTotalPages);
            return returnedLicenses;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return [];
        } finally {
            setLoading(false);
        }
    };

    /**
     * Creates a new license.
     *
     * @async
     * @function
     * @param {Object} newLicenseFields - The fields for creating the new license, passed from formik or elsewhere. Reference config/form-fields.js or validation/create-ts-plus-license-validations.js for details on properties.
     * @returns {Promise<TsPlusLicense>} A promise that resolves when the license creation is complete with the new license object.
     */
    const createLicense = async (newLicenseFields) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/licenses`;
            const response = await callApi(path, 'post', newLicenseFields);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const newLicense = await response.json();
            setLicense(newLicense);
            return newLicense;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Updates a license.
     *
     * @async
     * @function
     * @param {string} licenseId
     * @param {Object} newLicenseFields - The fields for creating the new license, passed from formik or elsewhere. Reference config/form-fields.js or validation/create-ts-plus-license-validations.js for details on properties.
     * @returns {Promise<Computer>} A promise that resolves when the computer creation is complete with the new computer object.
     */
    const updateLicense = async (licenseId, updateLicenseFields) => {
        try {
            setLoading(true);
            setError(null);

            const updateLicenseFieldsModified = { ...updateLicenseFields };

            // Remove any more fields that are not allowed
            delete updateLicenseFieldsModified.tech;
            delete updateLicenseFieldsModified.id;
            delete updateLicenseFieldsModified._id;

            const path = `/licenses/${licenseId}`;
            const response = await callApi(
                path,
                'patch',
                updateLicenseFieldsModified
            );

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const updatedLicense = await response.json();
            setLicense(updatedLicense);
            return updatedLicense;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets a single license and it's info by ID.
     *
     * @async
     * @function
     * @param {string} licenseId
     * @param {boolean} includeComputer -  whether or not to include computer info
     * @returns {Promise<TsPlusLicense>} A promise that resolves with the returned license.
     */
    const getLicense = async (licenseId, includeComputer = false) => {
        try {
            setLoading(true);
            setError(null);

            const path = includeComputer
                ? `/licenses/${licenseId}?includeComputer=true`
                : `/licenses/${licenseId}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedLicense = await response.json();
            setLicense(returnedLicense);
            return returnedLicense;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Resets all stats.
     *
     * @async
     * @function
     * @returns {Promise<void>} A promise that resolves when finished.
     */
    const reset = async () => {
        try {
            setError(null);
            setLicenses([]);
            setTotalPages(0);
            setLicense(null);
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    return {
        license,
        licenses,
        loading,
        error,
        createLicense,
        updateLicense,
        getLicense,
        getOldestAvailableLicenses,
        totalPages,
        reset,
        getAllLicenses
    };
};

export default useTsPlusLicenses;
