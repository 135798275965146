import { useState } from 'react';
import convertError from '../utils/error-converter.js';
import UserFriendlyError from '../utils/UserFriendlyError.js';
import callApi from '../utils/call-api.js';
import analyzeApiResponse from '../utils/analyze-api-response.js';

/**
 * Custom React hook for managing manual license-related data.
 *
 * @returns {Object} An object containing state variables and functions related to license data.
 * @property {Object|null} manualLicense - The returned license (null if none returned).
 * @property {boolean} loading - A boolean indicating whether data is currently being loaded.
 * @property {Object|null} error - An error object containing details about any encountered error.
 * @property {Function} createManualLicense - A function to create a new license.
 */
const useManualTsPlusLicenses = () => {
    const [manualLicense, setManualLicense] = useState(null);
    const [manualLicenses, setManualLicenses] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Gets all manual licenses.
     *
     * @async
     * @function
     *
     * @returns {Promise<Array>} A promise that resolves with a list of returned licenses.
     */
    const getAllManualLicenses = async () => {
        try {
            setLoading(true);
            setError(null);

            const path = '/manualLicenses';
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const results = await response.json();
            setManualLicenses(results);
            return results;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Creates a new manual license.
     *
     * @async
     * @function
     * @param {Object} newLicenseFields - The fields for creating the new license, passed from formik or elsewhere. Reference config/form-fields.js or validation/create-manual-ts-plus-license-validations.js for details on properties.
     * @returns {Promise<ManualTsPlusLicense>} A promise that resolves when the license creation is complete with the new license object.
     */
    const createManualLicense = async (newLicenseFields) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/manualLicenses`;
            const response = await callApi(path, 'post', newLicenseFields);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const newLicense = await response.json();
            setManualLicense(newLicense);
            return newLicense;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Updates a manual license.
     *
     * @async
     * @function
     * @param {string} licenseId
     * @param {Object} newLicenseFields - The fields for creating the new license, passed from formik or elsewhere. Reference config/form-fields.js or validation/create-manual-ts-plus-license-validations.js for details on properties.
     * @returns {Promise<Manual>} A promise that resolves when the license update is complete with the new license object.
     */
    const updateManualLicense = async (licenseId, updateLicenseFields) => {
        try {
            setLoading(true);
            setError(null);

            const updateLicenseFieldsModified = { ...updateLicenseFields };

            // Remove any more fields that are not allowed
            delete updateLicenseFieldsModified.tech;
            delete updateLicenseFieldsModified.id;
            delete updateLicenseFieldsModified._id;

            const path = `/manualLicenses/${licenseId}`;
            const response = await callApi(
                path,
                'patch',
                updateLicenseFieldsModified
            );

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const updatedLicense = await response.json();
            setManualLicense(updatedLicense);
            return updatedLicense;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets a single manual license and it's info by ID.
     *
     * @async
     * @function
     * @param {string} licenseId
     * @returns {Promise<ManualTsPlusLicense>} A promise that resolves with the returned license.
     */
    const getManualLicense = async (licenseId) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/manualLicenses/${licenseId}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedLicense = await response.json();
            setManualLicense(returnedLicense);
            return returnedLicense;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Resets all stats.
     *
     * @async
     * @function
     * @returns {Promise<void>} A promise that resolves when finished.
     */
    const reset = async () => {
        try {
            setError(null);
            setManualLicenses([]);
            setTotalPages(0);
            setManualLicense(null);
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    return {
        manualLicense,
        manualLicenses,
        loading,
        error,
        createManualLicense,
        updateManualLicense,
        getManualLicense,
        totalPages,
        reset,
        getAllManualLicenses
    };
};

export default useManualTsPlusLicenses;
