import * as Yup from 'yup';
import { validComputerSysName } from './custom-validations.js';

/**
 * Validation schema for creating a new computer.
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} computerName - Validation for the computer name.
 * @property {Yup.StringSchema} computerSysName - Validation for the computer systematic name.
 * @property {Yup.StringSchema} OS - Validation for the operating system.
 * @property {Yup.BooleanSchema} avInstalled - Validation for the AV (Antivirus) installed.
 * @property {Yup.StringSchema} avName - Validation for the AV name.
 * @property {Yup.StringSchema} location - Validation for the computer location.
 * @property {Yup.StringSchema} syncroStatus - Validation for the syncro status.
 * @property {Yup.StringSchema} domain - Validation for the computer domain.
 * @property {Yup.StringSchema} note - Validation for the computer note.
 * @property {Yup.BooleanSchema} isITOnly - Validation for the is IT only.
 * @property {Yup.StringSchema} installTech
 * @property {Yup.DateSchema} installDate
 * @property {Yup.NumberSchema} splashtopId - Validation for the splashtop ID.
 */
const createComputerValidation = Yup.object({
    computerName: Yup.string().trim('Remove any leading or trailing spaces'),
    computerSysName: Yup.string()
        .required('Computer systematic name is required')
        .matches(validComputerSysName, {
            message:
                'Computer systematic name must be in the following format: state.city.lastName.computerDescription where state is a two character abbreviation and there are no spaces (_ in place of spaces if multiple words present)'
        })
        .trim('Remove any leading or trailing spaces'),
    OS: Yup.string().required('Operating System is required').trim(),
    avInstalled: Yup.boolean().required('AV Installed is required'),
    avName: Yup.string().trim('Remove any leading or trailing spaces'),
    location: Yup.string().trim('Remove any leading or trailing spaces'),
    syncroStatus: Yup.string().trim('Remove any leading or trailing spaces'),
    domain: Yup.string().trim('Remove any leading or trailing spaces'),
    note: Yup.string().trim('Remove any leading or trailing spaces'),
    isITOnly: Yup.boolean(),
    installTech: Yup.string().trim('Remove any leading or trailing spaces'),
    installDate: Yup.date().nullable(),
    splashtopId: Yup.number().positive().integer().nullable(),
    tsPlusLicenseId: Yup.string().nullable()
});

export default createComputerValidation;
