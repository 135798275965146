import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import useTsPlusLicenses from '../../hooks/useTsPlusLicenses.js';
import LoadingSpinner from '../../components/common/LoadingSpinner.jsx';
import ErrorMessage from '../../components/common/ErrorMessage.jsx';
import TsPlusLicensesTable from '../../components/ts-plus-licenses/TsPlusLicensesTable.jsx';
import useManualTsPlusLicenses from '../../hooks/useManualTsPlusLicenses.js';

/**
 * React component for the page displaying a TSplus license info.
 *
 * @component
 * @returns {JSX.Element} The JSX representation of the OfficeNotesListPage component.
 */
const TsPlusLicensesListPage = () => {
    const dispatch = useDispatch();

    const {
        licenses,
        error: licensesError,
        loading: licensesLoading,
        getAllLicenses
    } = useTsPlusLicenses();
    const {
        manualLicenses,
        error: manualLicensesError,
        loading: manualLicensesLoading,
        getAllManualLicenses
    } = useManualTsPlusLicenses();

    // Sets the navigation tab
    useEffect(() => {
        dispatch(setActiveTab('TSplus'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    useEffect(() => {
        const getLicenses = async () => {
            await getAllLicenses(true);
            await getAllManualLicenses();
        };

        getLicenses();
    }, []);

    return (
        <div className="notes-container">
            <div className="notes-header-container-new">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content'
                    }}>
                    <h2 className="notes-title">TSplus Licenses</h2>
                    <Link
                        to="/addManualLicense"
                        className="automation-link"
                        style={{
                            position: 'absolute',
                            top: '4.2em',
                            right: '1.5em'
                        }}>
                        Manually Add a License (for misc licenses)
                    </Link>
                </div>

                {(licensesLoading || manualLicensesLoading) && (
                    <LoadingSpinner />
                )}
                {licensesError && (
                    <ErrorMessage message={licensesError.message} />
                )}
                {manualLicensesError && (
                    <ErrorMessage message={manualLicensesError.message} />
                )}
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                {(licenses.length > 0 || manualLicenses.length > 0) && (
                    <TsPlusLicensesTable
                        licenses={[...licenses, ...manualLicenses]}
                    />
                )}
            </div>
        </div>
    );
};

export default TsPlusLicensesListPage;
