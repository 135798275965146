import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import SubmitButton from '../common/SubmitButton.jsx';
import { createLicenseFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import createLicenseValidation from '../../validation/create-ts-plus-license-validations.js';
import useTsPlusLicenses from '../../hooks/useTsPlusLicenses.js';
import useComputers from '../../hooks/useComputers.js';

/**
 * AddTsPlusLicenseToComputerForm component for rendering the form to add a ts plus license to a computer.
 *
 * @component
 * @returns {JSX.Element} - The rendered AddTsPlusLicenseToComputerForm component.
 */
const AddTsPlusLicenseToComputerForm = ({
    computerId,
    computer,
    officeSysName
}) => {
    const navigate = useNavigate();

    const [selectedLicenseId, setSelectedLicenseId] = useState(null);
    const [licenseDropdownOptions, setLicenseDropdownOptions] = useState([]);
    const [createNewLicenseActive, setCreateNewLicenseActive] = useState(false);
    const [refreshAvailableLicenses, setRefreshAvailableLicenses] = useState(0);

    const {
        error: licensesError,
        loading: licensesLoading,
        getOldestAvailableLicenses,
        createLicense,
        updateLicense,
        license,
        getLicense,
        reset: licensesReset
    } = useTsPlusLicenses();

    const {
        error: computerError,
        loading: computerLoading,
        updateComputer,
        reset: computersReset
    } = useComputers();

    const formik = useFormik({
        initialValues: { ...createLicenseFormFields, computerId },
        validationSchema: createLicenseValidation,
        onSubmit: async (values) => {
            const newLicense = await createLicense({
                ...values,
                computerId
            });
            if (newLicense) {
                const updatedComputer = await updateComputer(
                    computerId,
                    { tsPlusLicenseId: newLicense.id },
                    computer,
                    officeSysName
                );

                if (updatedComputer) {
                    navigate(`/office/${updatedComputer.officeId}`);
                }
            }
        }
    });

    useEffect(() => {
        const getLicenses = async () => {
            let returnedLicenses = [];
            returnedLicenses = await getOldestAvailableLicenses();
            returnedLicenses = returnedLicenses.map((l) => {
                // eslint-disable-next-line no-param-reassign
                l = {
                    value: l.id,
                    label: l.license
                };
                return l;
            });

            setLicenseDropdownOptions(returnedLicenses);
            if (returnedLicenses.length > 0) {
                setSelectedLicenseId(returnedLicenses[0].value);
            }
        };

        getLicenses();
    }, [refreshAvailableLicenses]);

    useEffect(() => {
        const getLicenseDetails = async () => {
            licensesReset();
            computersReset();
            formik.resetForm();
            if (selectedLicenseId) {
                await getLicense(selectedLicenseId);
            }
        };
        getLicenseDetails();
    }, [selectedLicenseId]);

    const handleClickLeft = () => {
        if (createNewLicenseActive) {
            setCreateNewLicenseActive(false);
            setSelectedLicenseId(null);
            setRefreshAvailableLicenses(refreshAvailableLicenses + 1);
            licensesReset();
            computersReset();
            formik.resetForm();
        }
    };

    const handleClickRight = () => {
        if (!createNewLicenseActive) {
            setCreateNewLicenseActive(true);
            setSelectedLicenseId(null);
            licensesReset();
            computersReset();
            formik.resetForm();
        }
    };

    return (
        <>
            {/* Toggle button for add vs create process */}
            <div
                style={{
                    display: 'flex',
                    width: '50%',
                    height: '40px',
                    marginBottom: '2em',
                    marginLeft: '30px',
                    marginTop: '2em'
                }}>
                <div
                    className={
                        !createNewLicenseActive
                            ? 'reporting-query-selector-active'
                            : 'reporting-query-selector-inactive'
                    }
                    onClick={handleClickLeft}
                    id="reporting-query-selector-left">
                    <p
                        style={{
                            color: !createNewLicenseActive ? 'white' : '#2361DC'
                        }}>
                        Add available license
                    </p>
                </div>
                <div
                    className={
                        createNewLicenseActive
                            ? 'reporting-query-selector-active'
                            : 'reporting-query-selector-inactive'
                    }
                    id="reporting-query-selector-right"
                    onClick={handleClickRight}>
                    <p
                        style={{
                            color: createNewLicenseActive ? 'white' : '#2361DC'
                        }}>
                        Create new license
                    </p>
                </div>
            </div>

            {/* Add existing license */}
            {!createNewLicenseActive && (
                <>
                    <div className="newsc-form-container">
                        <h4>
                            This is autofilled with the oldest available license
                            with at least 2 weeks of use left
                        </h4>
                        <p className="form-label">Select an existing license</p>
                        <Select
                            id="selectLicense"
                            name="selectLicense"
                            options={licenseDropdownOptions}
                            value={licenseDropdownOptions.find(
                                (option) => option.value === selectedLicenseId
                            )}
                            onChange={(e) => setSelectedLicenseId(e.value)}
                        />
                    </div>
                    <br />
                    <br />
                    {licensesLoading && <LoadingSpinner />}
                    {licensesError && (
                        <ErrorMessage message={licensesError.message} />
                    )}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            height: 'fit-content'
                        }}>
                        {licenseDropdownOptions.length < 1 && (
                            <p style={{ margin: '1em' }}>
                                No available licenses. Please create a new one.
                            </p>
                        )}
                        {license && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                    height: 'fit-content',
                                    justifyContent: 'flex-start',
                                    alignContent: 'left'
                                }}>
                                <form
                                    onSubmit={async (e) => {
                                        e.preventDefault();
                                        const updatedComputer =
                                            await updateComputer(
                                                computerId,
                                                {
                                                    tsPlusLicenseId:
                                                        selectedLicenseId
                                                },
                                                computer,
                                                officeSysName
                                            );
                                        if (updatedComputer) {
                                            const updatedLicense =
                                                await updateLicense(
                                                    selectedLicenseId,
                                                    {
                                                        computerId:
                                                            updatedComputer.id
                                                    }
                                                );
                                            if (updatedLicense) {
                                                navigate(
                                                    `/office/${updatedComputer.officeId}`
                                                );
                                            }
                                        }
                                    }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            margin: '1em',
                                            alignItems: 'center',
                                            justifyContent: 'left'
                                        }}>
                                        <table className="office-table">
                                            <tbody>
                                                <tr
                                                    style={{
                                                        padding: '.25em'
                                                    }}>
                                                    <td className="office-table-left-cell">
                                                        # of Users:
                                                    </td>
                                                    <td className="office-table-right-cell">
                                                        {license.numOfUsers}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="office-table-left-cell">
                                                        Start Date:
                                                    </td>
                                                    <td className="office-table-right-cell">
                                                        {license.startDate
                                                            ? `${
                                                                  new Date(
                                                                      license.startDate
                                                                  ).getUTCMonth() +
                                                                  1
                                                              }/${new Date(
                                                                  license.startDate
                                                              ).getUTCDate()}/${new Date(
                                                                  license.startDate
                                                              ).getUTCFullYear()}`
                                                            : ''}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="office-table-left-cell">
                                                        Expire Date:
                                                    </td>
                                                    <td className="office-table-right-cell">
                                                        {license.expireDate
                                                            ? `${
                                                                  new Date(
                                                                      license.expireDate
                                                                  ).getUTCMonth() +
                                                                  1
                                                              }/${new Date(
                                                                  license.expireDate
                                                              ).getUTCDate()}/${new Date(
                                                                  license.expireDate
                                                              ).getUTCFullYear()}`
                                                            : ''}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <SubmitButton
                                            label="Add"
                                            style={{
                                                width: '5em'
                                            }}
                                        />
                                    </div>
                                </form>
                            </div>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '50%',
                                height: 'fit-content',
                                justifyContent: 'flex-start',
                                alignContent: 'left'
                            }}>
                            {licensesLoading && <LoadingSpinner />}
                            {licensesError && (
                                <ErrorMessage message={licensesError.message} />
                            )}
                            {computerLoading && <LoadingSpinner />}
                            {computerError && (
                                <ErrorMessage message={computerError.message} />
                            )}
                        </div>
                    </div>
                </>
            )}

            {/* Create new license */}
            {createNewLicenseActive && (
                <>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="newsc-form-container">
                            <label htmlFor="license" className="form-label">
                                License
                            </label>
                            <input
                                id="license"
                                name="license"
                                type="text"
                                className="newsc-input"
                                placeholder="e.g. XXXX-XXXX-XXXX-XXXX"
                                {...formik.getFieldProps('license')}
                            />
                            {formik.touched.license && formik.errors.license ? (
                                <ErrorMessage message={formik.errors.license} />
                            ) : null}

                            <label htmlFor="numOfUsers" className="form-label">
                                # of Users
                            </label>
                            <input
                                id="numOfUsers"
                                name="numOfUsers"
                                type="number"
                                className="newsc-input"
                                {...formik.getFieldProps('numOfUsers')}
                            />
                            {formik.touched.numOfUsers &&
                            formik.errors.numOfUsers ? (
                                <ErrorMessage
                                    message={formik.errors.numOfUsers}
                                />
                            ) : null}

                            <label htmlFor="startDate" className="form-label">
                                Start Date
                            </label>
                            <input
                                id="startDate"
                                name="startDate"
                                type="date"
                                className="newsc-input"
                                {...formik.getFieldProps('startDate')}
                            />
                            {formik.touched.startDate &&
                            formik.errors.startDate ? (
                                <ErrorMessage
                                    message={formik.errors.startDate}
                                />
                            ) : null}

                            <label htmlFor="expireDate" className="form-label">
                                Expire Date
                            </label>
                            <input
                                id="expireDate"
                                name="expireDate"
                                type="date"
                                className="newsc-input"
                                {...formik.getFieldProps('expireDate')}
                            />
                            {formik.touched.expireDate &&
                            formik.errors.expireDate ? (
                                <ErrorMessage
                                    message={formik.errors.expireDate}
                                />
                            ) : null}
                            <SubmitButton label="Save and add" />
                        </div>
                    </form>
                    <br />
                    <br />
                    {licensesLoading && <LoadingSpinner />}
                    {computerLoading && <LoadingSpinner />}
                    {licensesError && (
                        <ErrorMessage message={licensesError.message} />
                    )}
                    {computerError && (
                        <ErrorMessage message={computerError.message} />
                    )}
                    {license && computer && (
                        <SuccessMessage message="New license created and added successfully!" />
                    )}
                </>
            )}
        </>
    );
};

AddTsPlusLicenseToComputerForm.propTypes = {
    computerId: PropTypes.string.isRequired,
    computer: PropTypes.object.isRequired,
    officeSysName: PropTypes.string.isRequired
};

export default AddTsPlusLicenseToComputerForm;
