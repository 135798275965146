import SubLinks from '../../components/header/SubLinks.jsx';
import { reportingLinks } from '../../config/standard-links.js';
import BackArrow from '../../components/common/BackArrow.jsx';
import AuditTicketsForm from '../../components/reporting/AuditTicketsForm.jsx';

/**
 * Component for rendering the page for Audit Ticket tool.
 * @returns {JSX.Element} AuditTicketPage component.
 */
const AuditTicketPage = () => {
    return (
        <div>
            <SubLinks links={reportingLinks} />
            <div className="newsc-container">
                <br />
                <br />
                <br />
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>Audit Tickets</h2>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '80vh',
                        width: '100%'
                    }}>
                    <AuditTicketsForm />
                </div>
            </div>
        </div>
    );
};

export default AuditTicketPage;
