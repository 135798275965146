import * as Yup from 'yup';

/**
 * Yup validation schema for getting license reporting data.
 *
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} timePeriod - Validation for the time period.
 * @property {Yup.DateSchema} customStartDate - Validation for the custom start date.
 * @property {Yup.DateSchema} customEndDate - Validation for the custom end date.
 */
const getLicenseReportingDataValidation = Yup.object({
    timePeriod: Yup.string()
        .trim('Remove any leading or trailing spaces')
        .required('Required'),
    customStartDate: Yup.date().nullable(),
    customEndDate: Yup.date().nullable()
});

export default getLicenseReportingDataValidation;
