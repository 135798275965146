import * as Yup from 'yup';

/**
 * Validation schema for creating a new manual TS plus license.
 * @constant
 * @type {object}
 * @property {Yup.StringSchema} license
 * @property {Yup.NumberSchema} numOfUsers
 * @property {Yup.DateSchema} startDate
 * @property {Yup.DateSchema} expireDate
 */
const createManualLicenseValidation = Yup.object({
    license: Yup.string()
        .required('Required')
        .trim('Remove any leading or trailing spaces')
        .uppercase(),
    numOfUsers: Yup.number().required('Required').integer().positive(),
    startDate: Yup.date().required('Required'),
    expireDate: Yup.date().required('Required')
});

export default createManualLicenseValidation;
