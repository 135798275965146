import { useState } from 'react';
import convertError from '../utils/error-converter.js';
import UserFriendlyError from '../utils/UserFriendlyError.js';
import callApi from '../utils/call-api.js';
import analyzeApiResponse from '../utils/analyze-api-response.js';

/**
 * Custom React hook for managing office-related data.
 *
 * @returns {Object} An object containing state variables and functions related to office data.
 * @property {Array<Object>} offices - The array of returned offices (empty if none returned).
 * @property {number} totalPages - The total number of pages for pagination.
 * @property {Object|null} office - The returned office (null if none returned).
 * @property {boolean} loading - A boolean indicating whether data is currently being loaded.
 * @property {Object|null} error - An error object containing details about any encountered error.
 * @property {Function} searchOffices - A function to search for offices based on criteria.
 * @property {Function} createOffice - A function to create a new office.
 * @property {Function} updateOffice - A function to update an office.
 * @property {Function} getOffice - A function to get a specific office by id.
 */
const useOffices = () => {
    const [offices, setOffices] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [office, setOffice] = useState(null);
    const [distinctValues, setDistinctValues] = useState([]);
    const [imageUrl, setImageUrl] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Searches for offices based on specified criteria.
     * @async
     * @function
     * @param {string} [officeSysName=''] - The search string for filtering offices by office systematic name.
     * @param {string} [type='active'] - The type of offices to search (e.g., 'active', 'retired', 'all').
     * @param {string} [officeName=''] - The search string for filtering offices by office name.
     * @param {string} [computerName=''] - The search string for filtering offices by computer name.
     * @param {string} [phoneNumber=''] - The search string for filtering offices by phone number.
     * @param {string} [dentalSoftware=''] - The search string for filtering offices by dental software.
     * @param {string} [eClaims=''] - The search string for filtering offices by eClaims.
     * @param {string} [xrays=''] - The search string for filtering offices by X-Rays.
     * @param {string} [os=''] - The search string for filtering offices by operating system.
     * @param {number} [currentPage=1] - The current page for pagination.
     * @param {number} [limit=40] - The number of offices to retrieve per page.
     * @param {string} [sortBy='officeSysName:asc'] - The sorting criteria for the retrieved offices.
     * @returns {Promise<Array>} A promise that resolves with a list of returned offices.
     */
    const searchOffices = async (
        officeSysName = '',
        type = 'active',
        officeName = '',
        computerName = '',
        phoneNumber = '',
        dentalSoftware = '',
        eClaims = '',
        xrays = '',
        os = '',
        currentPage = 1,
        limit = 40,
        sortBy = 'officeSysName:asc'
    ) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/offices/search/query?officeSysName=${officeSysName}&type=${type}&officeName=${officeName}&computerName=${computerName}&phoneNumber=${phoneNumber}&dentalSoftware=${dentalSoftware}&eClaims=${eClaims}&xrays=${xrays}&os=${os}&limit=${limit}&page=${currentPage}&sortBy=${sortBy}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const { results: returnedOffices, totalPages: returnedTotalPages } =
                await response.json();
            setOffices(returnedOffices);
            setTotalPages(returnedTotalPages);
            return returnedOffices;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets all offices.
     *
     * @async
     * @function
     * @returns {Promise<Array>} A promise that resolves with a list of returned offices.
     */
    const getAllOffices = async () => {
        try {
            setLoading(true);
            setError(null);

            const path = `/offices`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const results = await response.json();
            setOffices(results);
            return results;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Creates a new office.
     *
     * @async
     * @function
     * @param {Object} newOfficeFields - The fields for creating the new office, passed from formik. Reference config/form-fields.js or validation/create-office-validations.js for details on properties.
     * @returns {Promise<Office>} A promise that resolves when the office creation is complete with the new office object.
     */
    const createOffice = async (newOfficeFields) => {
        try {
            setLoading(true);
            setError(null);

            const returnedOffices = await searchOffices(
                newOfficeFields.officeSysName,
                'all'
            );
            if (returnedOffices.length > 0) {
                throw new UserFriendlyError(
                    'Office Systematic Name already exists'
                );
            }

            const newOfficeFieldsModified = newOfficeFields;
            newOfficeFieldsModified.uniqueVPN.hasUniqueVPN =
                newOfficeFields.connectionSoftware === 'unique vpn';

            const path = `/offices`;
            const response = await callApi(
                path,
                'post',
                newOfficeFieldsModified
            );

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const newOffice = await response.json();
            setOffice(newOffice);
            return newOffice;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Updates an office.
     *
     * @async
     * @function
     * @param {String} officeId
     * @param {Object} updateOfficeFields - The fields for updating the office, passed from formik. Reference config/form-fields.js or validation/create-office-validations.js for details on properties.
     * @param {Object} originalOffice - The original office before changes for reference.
     * @returns {Promise<Office>} A promise that resolves when the office update is complete with the office object.
     */
    const updateOffice = async (
        officeId,
        updateOfficeFields,
        originalOffice
    ) => {
        try {
            setLoading(true);
            setError(null);

            const updateOfficeFieldsModified = { ...updateOfficeFields };

            // If the officeSysName has changed, then check if one already exsits
            if (
                updateOfficeFields.officeSysName &&
                updateOfficeFields.officeSysName !==
                    originalOffice.officeSysName
            ) {
                const returnedOffices = await searchOffices(
                    updateOfficeFields.officeSysName,
                    'all'
                );
                if (returnedOffices.length > 0) {
                    throw new UserFriendlyError(
                        'Office Systematic Name already exists'
                    );
                }
            }
            // officeSysName has not changed, remove it from fields to pass to backend
            else {
                delete updateOfficeFieldsModified.officeSysName;
            }

            if (updateOfficeFieldsModified.uniqueVPN) {
                updateOfficeFieldsModified.uniqueVPN = {
                    ...updateOfficeFields.uniqueVPN
                };
                updateOfficeFieldsModified.uniqueVPN.hasUniqueVPN =
                    updateOfficeFieldsModified.connectionSoftware ===
                    'unique vpn';
            }

            // Remove any more fields that are not allowed
            delete updateOfficeFieldsModified.hcPasswords;
            delete updateOfficeFieldsModified.hcAV;
            delete updateOfficeFieldsModified.hcOS;
            delete updateOfficeFieldsModified.hcVPN;
            delete updateOfficeFieldsModified.hcDate;
            delete updateOfficeFieldsModified.hcFailDate;
            delete updateOfficeFieldsModified.kocDate;
            delete updateOfficeFieldsModified.reasonCompliant;
            delete updateOfficeFieldsModified.optedOutOfHipaaEmails;
            delete updateOfficeFieldsModified.nextCloudQrCodeImageObjectKey;

            const path = `/offices/${officeId}`;
            const response = await callApi(
                path,
                'patch',
                updateOfficeFieldsModified
            );

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const updatedOffice = await response.json();
            setOffice(updatedOffice);
            return updatedOffice;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets a single office and it's info by ID.
     *
     * @async
     * @function
     * @param {string} officeId
     * @param {boolean} includeAll -  whether or not to include computers and credentials
     * @returns {Promise<Office>} A promise that resolves with the returned office.
     */
    const getOffice = async (officeId, includeAll = false) => {
        try {
            setLoading(true);
            setError(null);

            const path = includeAll
                ? `/offices/${officeId}?includeAll=true`
                : `/offices/${officeId}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedOffice = await response.json();
            setOffice(returnedOffice);
            return returnedOffice;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Duplicates a single office and it's info by ID.
     *
     * @async
     * @function
     * @param {string} officeId
     * @returns {Promise<Office>} A promise that resolves with the returned new office.
     */
    const duplicateOffice = async (officeId) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/offices/duplicate/${officeId}`;
            const response = await callApi(path, 'post');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedOffice = await response.json();
            setOffice(returnedOffice);
            return returnedOffice;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Searches for install fail offices based on specified criteria.
     *
     * @async
     * @function
     * @param {string} [searchString=''] - The search string for filtering offices.
     * @param {number} [currentPage=1] - The current page for pagination.
     * @param {number} [limit=40] - The number of offices to retrieve per page.
     * @returns {Promise<Array>} A promise that resolves with a list of returned offices.
     */
    const searchInstallFailOffices = async (
        searchString = '',
        currentPage = 1,
        limit = 40
    ) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/install-fails?search=${searchString}&limit=${limit}&page=${currentPage}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const { results: returnedOffices, totalPages: returnedTotalPages } =
                await response.json();
            setOffices(returnedOffices);
            setTotalPages(returnedTotalPages);
            return returnedOffices;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Gets the distinct/unique values of an office property.
     *
     * @async
     * @function
     * @param {string} propertyName
     * @returns {Promise<Array>} A promise that resolves with an array of values.
     */
    const getDistinctOfficeProperty = async (propertyName) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/offices/distinct/values?propertyName=${propertyName}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedValues = await response.json();
            setDistinctValues(returnedValues);
            return returnedValues;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return [];
        } finally {
            setLoading(false);
        }
    };

    /**
     * Get presigned url to view QR code image.
     *
     * @async
     * @function
     * @param {string} imageKey
     * @returns {Promise<Office>} A promise that resolves with the url.
     */
    const viewQrCodeImage = async (imageKey) => {
        try {
            setLoading(true);
            setError(null);

            const path = `/offices/view/qrcode?imageKey=${imageKey}`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const returnedUrl = await response.text();
            setImageUrl(returnedUrl);
            return returnedUrl;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Uploads a new QR code image.
     *
     * @async
     * @function
     * @param {String} officeId
     * @param {Object} file
     * @returns {Promise<Office>} A promise that resolves with the updated office.
     */
    const uploadQrCodeImage = async (officeId, file) => {
        try {
            setLoading(true);
            setError(null);

            const fileData = new FormData();
            fileData.append('file', file);

            const path = `/offices/upload/qrcode/${officeId}`;
            const response = await callApi(path, 'file', fileData);

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const updatedOffice = await response.json();
            setOffice(updatedOffice);
            return updatedOffice;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
            return null;
        } finally {
            setLoading(false);
        }
    };

    /**
     * Resets the QR code image.
     *
     * @async
     * @function
     * @returns {Promise<void>} A promise that resolves.
     */
    const resetQrCodeImage = async () => {
        setImageUrl('');
    };

    /**
     * Gets all hub offices.
     *
     * @async
     * @function
     * @returns {Promise<Array>} A promise that resolves with a list of returned hub offices.
     */
    const getAllHubOffices = async () => {
        try {
            setLoading(true);
            setError(null);

            const path = `/offices/hub/offices`;
            const response = await callApi(path, 'get');

            if (!response.ok) {
                const errorMessage = await analyzeApiResponse(response);
                throw new UserFriendlyError(errorMessage);
            }

            const results = await response.json();
            setOffices(results);
            return results;
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Resets all stats.
     *
     * @async
     * @function
     * @returns {Promise<void>} A promise that resolves when finished.
     */
    const reset = async () => {
        try {
            setError(null);
            setOffice(null);
            setTotalPages(0);
            setOffices([]);
            setDistinctValues([]);
            setTotalPages(0);
            setImageUrl('');
        } catch (err) {
            const convertedUserFriendlyError = convertError(err);
            setError(convertedUserFriendlyError);
        } finally {
            setLoading(false);
        }
    };

    return {
        offices,
        totalPages,
        office,
        loading,
        error,
        searchOffices,
        createOffice,
        updateOffice,
        getOffice,
        duplicateOffice,
        searchInstallFailOffices,
        getAllOffices,
        getDistinctOfficeProperty,
        distinctValues,
        viewQrCodeImage,
        imageUrl,
        uploadQrCodeImage,
        resetQrCodeImage,
        getAllHubOffices,
        reset
    };
};

export default useOffices;
