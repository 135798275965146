import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import { IoOpenOutline } from 'react-icons/io5';
// eslint-disable-next-line import/extensions
import { FaRegCopy } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import pearlUrl from '../../config/pearl-url.js';
import { blue } from '../../styles/standard-colors.js';

/**
 * Component for rendering a ticket card.
 * @param {object} props - The props object.
 * @param {string} props.ticket - The ticket object.
 * @returns {JSX.Element} The JSX element representing the TicketCard component.
 */
const TicketCard = ({ ticket }) => {
    const [copySuccess, setCopySuccess] = useState(false);

    const handleCopy = () => {
        navigator.clipboard
            .writeText(ticket.humanId)
            .then(() => {
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 2000);
            })
            .catch((err) => {
                console.error('Error copying text: ', err);
            });
    };

    return (
        <div className="ticket-card" key={uuidv4()}>
            <p>
                Ticket ID: {ticket.humanId}
                <FaRegCopy
                    onClick={handleCopy}
                    color={blue}
                    style={{ cursor: 'pointer', marginLeft: '.25rem' }}
                />
                {copySuccess && <span className="copy-tooltip">Copied!</span>}
            </p>

            <a
                href={`${pearlUrl}/search`}
                target="_blank"
                rel="noopener noreferrer">
                Open ticket search
                <IoOpenOutline style={{ marginLeft: '.25rem' }} />
            </a>
        </div>
    );
};

TicketCard.propTypes = {
    ticket: PropTypes.object.isRequired
};

export default TicketCard;
