import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import BackArrow from '../../components/common/BackArrow.jsx';
import CreateUpdateTsPlusLicenseForm from '../../components/ts-plus-licenses/CreateUpdateTsPlusLicenseForm.jsx';

/**
 * React component for the page displaying a TSplus create/update form.
 *
 * @component
 * @returns {JSX.Element} The JSX representation of the CreateUpdateTsPlusLicensePage component.
 */
const CreateUpdateTsPlusLicensePage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { objectToUpdate } = location.state;

    // Sets the navigation tab
    useEffect(() => {
        dispatch(setActiveTab('TSplus'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div>
            <div className="newsc-container">
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    <h2>Edit license</h2>
                </div>
                <br />
                <CreateUpdateTsPlusLicenseForm
                    objectToUpdate={objectToUpdate}
                />
            </div>
        </div>
    );
};

export default CreateUpdateTsPlusLicensePage;
