/* eslint-disable react/jsx-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTable, useSortBy, useFilters } from 'react-table';
import { green, red, yellow } from '../../styles/standard-colors.js';

/**
 * TsPlusLicensesTable component for displaying a table of TSplus licenses.
 *
 * @component
 * @example
 * // Example usage:
 * <TsPlusLicensesTable licenses={licenseList} />
 *
 * @param {Object} props - The component props.
 * @param {Array} props.licenses - The array of license objects to be displayed in the table (required).
 *
 * @returns {JSX.Element} - The rendered TsPlusLicensesTable component.
 */
const TsPlusLicensesTable = ({ licenses }) => {
    const navigate = useNavigate();

    const filterTypes = useMemo(
        () => ({
            stringMatchDate: (rows, id, filterValue) => {
                if (!filterValue) return rows; // No filter applied

                return rows.filter((row) => {
                    const cellValue = new Date(row.values[id]);
                    const filterDate = new Date(filterValue);
                    // Compare only the date part in UTC
                    return (
                        cellValue.getUTCFullYear() ===
                            filterDate.getUTCFullYear() &&
                        cellValue.getUTCMonth() === filterDate.getUTCMonth() &&
                        cellValue.getUTCDate() === filterDate.getUTCDate()
                    );
                });
            }
        }),
        []
    );

    // Default text filter
    const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => (
        <input
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value || undefined)}
            placeholder="Search..."
            style={{ width: '100%' }}
        />
    );

    const ExactNumberFilter = ({ column: { filterValue, setFilter } }) => (
        <input
            type="number"
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value || undefined)}
            placeholder="Exact number"
            style={{ width: '100%' }}
        />
    );

    const StringMatchDateFilter = ({ column: { filterValue, setFilter } }) => (
        <input
            type="date" // Use a date input for better usability
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value || undefined)}
            placeholder="Enter date"
            style={{ width: '100%' }}
        />
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Status',
                accessor: 'status',
                Filter: DefaultColumnFilter,
                Cell: ({ value }) => {
                    // Set the background color based on the status
                    let backgroundColor = '';
                    let color = '';
                    if (value === 'Expiring soon') {
                        backgroundColor = yellow;
                    } else if (value === 'Expired') {
                        backgroundColor = red;
                        color = 'white';
                    } else if (value === 'Active') {
                        backgroundColor = green;
                    }

                    return (
                        <div
                            style={{
                                backgroundColor,
                                color,
                                padding: '5px',
                                textAlign: 'center'
                            }}>
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: '# of Days Left',
                accessor: 'daysRemaining',
                Filter: ExactNumberFilter
            },
            {
                Header: 'Active Time',
                accessor: 'activeTime',
                Filter: ExactNumberFilter
            },
            {
                Header: 'Office',
                accessor: 'computer.office.officeSysName',
                Filter: DefaultColumnFilter,
                Cell: ({ value, row }) => {
                    if (
                        row.original.computer.computerSysName === 'Misc License'
                    ) {
                        return 'Misc License';
                    }
                    return (
                        <Link
                            to={`/office/${row.original.computer.office._id}`}>
                            {value}
                        </Link>
                    );
                }
            },
            {
                Header: 'Computer',
                accessor: 'computer.computerSysName',
                Filter: DefaultColumnFilter
            },
            {
                Header: 'Tech',
                accessor: 'tech',
                Filter: DefaultColumnFilter
            },
            {
                Header: 'License',
                accessor: 'license',
                Filter: DefaultColumnFilter
            },
            {
                Header: '# of Users',
                accessor: 'numOfUsers',
                Filter: ExactNumberFilter
            },
            {
                Header: 'Start Date',
                accessor: 'startDate',
                Cell: ({ value }) =>
                    new Date(value).toLocaleDateString('en-US', {
                        timeZone: 'UTC'
                    }),
                Filter: StringMatchDateFilter,
                filter: 'stringMatchDate' // Use simplified string match filter
            },
            {
                Header: 'Expire Date',
                accessor: 'expireDate',
                Cell: ({ value }) =>
                    new Date(value).toLocaleDateString('en-US', {
                        timeZone: 'UTC'
                    }),
                Filter: StringMatchDateFilter,
                filter: 'stringMatchDate' // Use simplified string match filter
            },
            {
                Header: 'Actions', // New column for the button
                Cell: ({ row }) => {
                    const {
                        status,
                        daysRemaining,
                        activeTime,
                        computer,
                        tech,
                        ...objectToUpdate
                    } = row.original;

                    if (
                        row.original.computer.computerSysName === 'Misc License'
                    ) {
                        return (
                            <button
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    navigate(`/addManualLicense`, {
                                        state: { objectToUpdate }
                                    })
                                }>
                                Edit
                            </button>
                        );
                    }
                    return (
                        <button
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                navigate(`/editLicense`, {
                                    state: { objectToUpdate }
                                })
                            }>
                            Edit
                        </button>
                    );
                }
            }
        ],
        []
    );

    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter // Use default filter for all columns
        }),
        []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data: licenses,
                defaultColumn,
                filterTypes // Add custom filter types here
            },
            useFilters,
            useSortBy
        );

    // Calculate the total number of licenses based on the filtered rows
    const totalLicenses = rows.length;

    return (
        <>
            <div
                style={{
                    width: '97%',
                    display: 'flex',
                    justifyContent: 'left',
                    position: 'sticky',
                    zIndex: 1000,
                    top: 78,
                    backgroundColor: 'white'
                }}>
                Total Licenses: {totalLicenses}
            </div>
            <div className="table-container">
                <table {...getTableProps()} className="styled-table">
                    <thead style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                        <br />
                                        <br />
                                        <div>
                                            {column.canFilter
                                                ? column.render('Filter')
                                                : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

TsPlusLicensesTable.propTypes = {
    licenses: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TsPlusLicensesTable;
