import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setActiveTab } from '../../redux/slices/header-slice.js';
import BackArrow from '../../components/common/BackArrow.jsx';
import CreateUpdateManualTsPlusLicenseForm from '../../components/ts-plus-licenses/CreateUpdateManualTsPlusLicenseForm.jsx';

/**
 * React component for the page displaying a manual TSplus create/update form.
 *
 * @component
 * @returns {JSX.Element} The JSX representation of the CreateUpdateManualTsPlusLicensePage component.
 */
const CreateUpdateManualTsPlusLicensePage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const objectToUpdate =
        (location.state && location.state.objectToUpdate) || null;

    // Sets the navigation tab
    useEffect(() => {
        dispatch(setActiveTab('TSplus'));

        return () => {
            dispatch(setActiveTab(null));
        };
    });

    return (
        <div>
            <div className="newsc-container">
                <div className="create-office-heading">
                    <BackArrow navigateTo={-1} />
                    {objectToUpdate ? (
                        <h2>Edit license</h2>
                    ) : (
                        <h2>Create license</h2>
                    )}
                </div>
                <br />
                <CreateUpdateManualTsPlusLicenseForm
                    objectToUpdate={objectToUpdate}
                />
            </div>
        </div>
    );
};

export default CreateUpdateManualTsPlusLicensePage;
