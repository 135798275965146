import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import SubmitButton from '../common/SubmitButton.jsx';
import { createManualLicenseFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import SuccessMessage from '../common/SuccessMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import useManualTsPlusLicenses from '../../hooks/useManualTsPlusLicenses.js';
import createManualLicenseValidation from '../../validation/create-manual-ts-plus-license-validations.js';

/**
 * CreateUpdateManualTsPlusLicenseForm component for rendering the form to create a new manual license.
 *
 * @component
 * @returns {JSX.Element} - The rendered CreateUpdateManualTsPlusLicenseForm component.
 */
const CreateUpdateManualTsPlusLicenseForm = ({ objectToUpdate }) => {
    const {
        error,
        loading,
        createManualLicense,
        updateManualLicense,
        manualLicense
    } = useManualTsPlusLicenses();

    const formik = useFormik({
        initialValues: objectToUpdate || createManualLicenseFormFields,
        validationSchema: createManualLicenseValidation,
        onSubmit: async (values) => {
            if (objectToUpdate) {
                await updateManualLicense(objectToUpdate._id, values);
            } else {
                await createManualLicense(values);
            }
        }
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="newsc-form-container">
                    <label htmlFor="license" className="form-label">
                        License
                    </label>
                    <input
                        id="license"
                        name="license"
                        type="text"
                        className="newsc-input"
                        placeholder="e.g. XXXX-XXXX-XXXX-XXXX"
                        {...formik.getFieldProps('license')}
                    />
                    {formik.touched.license && formik.errors.license ? (
                        <ErrorMessage message={formik.errors.license} />
                    ) : null}

                    <label htmlFor="numOfUsers" className="form-label">
                        # of Users
                    </label>
                    <input
                        id="numOfUsers"
                        name="numOfUsers"
                        type="number"
                        className="newsc-input"
                        {...formik.getFieldProps('numOfUsers')}
                    />
                    {formik.touched.numOfUsers && formik.errors.numOfUsers ? (
                        <ErrorMessage message={formik.errors.numOfUsers} />
                    ) : null}

                    <label htmlFor="startDate" className="form-label">
                        Start Date
                    </label>
                    <input
                        id="startDate"
                        name="startDate"
                        type="date"
                        className="newsc-input"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        defaultValue={
                            formik.values.startDate
                                ? new Date(formik.values.startDate)
                                      .toISOString()
                                      .substring(0, 10)
                                : null
                        }
                    />
                    {formik.touched.startDate && formik.errors.startDate ? (
                        <ErrorMessage message={formik.errors.startDate} />
                    ) : null}

                    <label htmlFor="expireDate" className="form-label">
                        Expire Date
                    </label>
                    <input
                        id="expireDate"
                        name="expireDate"
                        type="date"
                        className="newsc-input"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        defaultValue={
                            formik.values.expireDate
                                ? new Date(formik.values.expireDate)
                                      .toISOString()
                                      .substring(0, 10)
                                : null
                        }
                    />
                    {formik.touched.expireDate && formik.errors.expireDate ? (
                        <ErrorMessage message={formik.errors.expireDate} />
                    ) : null}
                    {objectToUpdate && <SubmitButton label="Save" />}
                    {!objectToUpdate && <SubmitButton label="Create" />}
                </div>
            </form>
            <br />
            <br />
            {loading && <LoadingSpinner />}
            {error && <ErrorMessage message={error.message} />}
            {manualLicense && objectToUpdate && (
                <SuccessMessage message="New license updated successfully!" />
            )}
            {manualLicense && !objectToUpdate && (
                <SuccessMessage message="New license created and added successfully!" />
            )}
        </>
    );
};

CreateUpdateManualTsPlusLicenseForm.propTypes = {
    objectToUpdate: PropTypes.object
};

export default CreateUpdateManualTsPlusLicenseForm;
