import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import CheckBox from 'rc-checkbox';
import DatePicker from 'react-datepicker';
import { Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import SubmitButton from '../common/SubmitButton.jsx';
import { getLicenseReportingDataFormFields } from '../../config/form-fields.js';
import ErrorMessage from '../common/ErrorMessage.jsx';
import LoadingSpinner from '../common/LoadingSpinner.jsx';
import { timePeriodOptions } from '../../config/dropdown-options.js';
import StatTable from './StatTable.jsx';
import useReporting from '../../hooks/useReporting.js';
import getLicenseReportingDataValidation from '../../validation/get-license-reporting-data-validation.js';
import chartColors from '../../styles/chart-colors.js';

/**
 * LicenseDataForm component for running a query.
 *
 * @component
 * @returns {JSX.Element} - The rendered LicenseDataForm component.
 */
const LicenseDataForm = () => {
    const [showCustomTime, setShowCustomTime] = useState(false);

    const { error, loading, table, charts, getLicenseReportingData, reset } =
        useReporting();

    const formik = useFormik({
        initialValues: getLicenseReportingDataFormFields,
        validationSchema: getLicenseReportingDataValidation,
        onSubmit: async (values) => {
            const modifiedValues = { ...values };

            // Get rid of null fields or empty arrays
            Object.keys(modifiedValues).forEach((key) => {
                if (
                    modifiedValues[key] === null ||
                    (Array.isArray(modifiedValues[key]) &&
                        modifiedValues[key].length === 0)
                ) {
                    delete modifiedValues[key];
                }
            });

            await getLicenseReportingData(modifiedValues);
        }
    });

    useEffect(() => {
        if (showCustomTime) {
            formik.setFieldValue('timePeriod', 'custom');
        } else {
            formik.setFieldValue('timePeriod', '');
        }
    }, [showCustomTime]);

    // Scrolls to top of screen, UI is funky
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const renderCustomLabel = ({ percent }) => `${(percent * 100).toFixed(1)}%`;

    return (
        <>
            {/* Form and table results */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: '50vh',
                    marginBottom: '5vh'
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%'
                    }}>
                    <form
                        onSubmit={formik.handleSubmit}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            marginLeft: '30px',
                            marginTop: '2em'
                        }}>
                        <div
                            style={{
                                textAlign: 'left'
                            }}>
                            <label htmlFor="timePeriod">Time Period</label>
                            <br />
                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '80%',
                                    justifyContent: 'space-between'
                                }}>
                                {showCustomTime ? (
                                    <div className="hipaa-date-container">
                                        <div
                                            className="hipaa-date-chunk"
                                            style={{ zIndex: 0 }}>
                                            <p>Start Date</p>
                                            <DatePicker
                                                onChange={(date) =>
                                                    formik.setFieldValue(
                                                        'customStartDate',
                                                        date
                                                    )
                                                }
                                                selected={
                                                    formik.values
                                                        .customStartDate
                                                }
                                                maxDate={new Date()}
                                                minDate={new Date('2015-10-27')}
                                                isClearable
                                                showYearDropdown
                                                scrollableMonthYearDropdown
                                            />
                                        </div>
                                        <div
                                            className="hipaa-date-chunk"
                                            style={{ zIndex: 0 }}>
                                            <p>End Date</p>
                                            <DatePicker
                                                onChange={(date) =>
                                                    formik.setFieldValue(
                                                        'customEndDate',
                                                        date
                                                    )
                                                }
                                                selected={
                                                    formik.values.customEndDate
                                                }
                                                maxDate={new Date()}
                                                minDate={new Date('2015-10-27')}
                                                isClearable
                                                showYearDropdown
                                                scrollableMonthYearDropdown
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ width: '75%' }}>
                                        <Select
                                            id="timePeriod"
                                            name="timePeriod"
                                            options={timePeriodOptions}
                                            value={timePeriodOptions.find(
                                                (option) =>
                                                    option.value ===
                                                    formik.values.timePeriod
                                            )}
                                            onChange={(selectedOption) =>
                                                formik.setFieldValue(
                                                    'timePeriod',
                                                    selectedOption
                                                        ? selectedOption.value
                                                        : null
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                    <CheckBox
                                        checked={showCustomTime}
                                        onChange={() =>
                                            setShowCustomTime(!showCustomTime)
                                        }
                                    />
                                    <p>Use Custom</p>
                                </div>
                            </div>
                            {formik.touched.timePeriod &&
                            formik.errors.timePeriod ? (
                                <div
                                    style={{
                                        textAlign: 'center'
                                    }}>
                                    <ErrorMessage
                                        message={formik.errors.timePeriod}
                                    />
                                </div>
                            ) : null}
                        </div>
                        <br />
                        <br />
                        {Object.keys(formik.errors).length !== 0 &&
                        Object.keys(formik.touched).length !== 0 ? (
                            <ErrorMessage message="There are form errors above, please fix." />
                        ) : null}

                        <SubmitButton label="Run query" />
                    </form>
                </div>

                <br />

                {/* Table results */}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                        alignItems: 'center'
                    }}>
                    {loading && <LoadingSpinner />}
                    {error && <ErrorMessage message={error.message} />}
                    {table && (
                        <>
                            <StatTable
                                title={table.title}
                                rows={table.rows}
                                columnHeaders={table.headers}
                            />
                            <br />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    justifyContent: 'space-evenly'
                                }}>
                                <button
                                    className="newsc-btn"
                                    style={{ marginBottom: '5em' }}
                                    onClick={reset}>
                                    Reset
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* Chart results */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '50vh',
                    alignItems: 'center'
                }}>
                {' '}
                {charts && charts.length > 0 && (
                    <>
                        <h3 style={{ fontStyle: 'italic' }}>Number of Users</h3>
                        <ResponsiveContainer width="95%" height={450}>
                            <PieChart width={500} height={300}>
                                <Pie
                                    data={charts}
                                    dataKey="percentage"
                                    nameKey="numOfUsers"
                                    cx="50%" // X-coordinate of the center
                                    cy="50%" // Y-coordinate of the center
                                    outerRadius={150} // Radius of the pie
                                    fill="#8884d8"
                                    label={renderCustomLabel}>
                                    {charts.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={
                                                chartColors[
                                                    index % chartColors.length
                                                ]
                                            }
                                        />
                                    ))}
                                </Pie>
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                        <br />
                    </>
                )}
            </div>
        </>
    );
};

export default LicenseDataForm;
